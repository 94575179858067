import React from 'react'
import Breadcrumb from '../../components/site/breadcrumb'
import Project from '../../components/site/project'

const ProjectPage = () => {
  return (
    <div>
        <Breadcrumb page="Project"/>
        <Project/>
    </div>
  )
}

export default ProjectPage