import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import handleApiRequest from "../../libs/apiHandler";
import Loader from "../loader";
import EditRoom from "./editRoom";

const ViewRooms = ({ coldstore }) => {
  const [rooms, setRooms] = useState([]);
  const [editRoom, setEditRoom] = useState(false);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState("");
  useEffect(() => {
    fetchData();
  }, [editRoom]);
  const fetchData = async () => {
    try {
      const targetPath = `room/get-user-room/${coldstore?._id}`;
      const result = await handleApiRequest("GET", targetPath, null, true);
      setRooms(result);
      console.log(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const closeEditRoom = () => {
    setEditRoom(false);
  };

  const handleDelete = async (roomId) => {
    setLoading(true);
    try {
      const targetPath = `room/delete/${roomId}`;
      const result = await handleApiRequest("DELETE", targetPath, null, true);
      if (result.message) {
        Swal.fire("Success", result.message, "success");
        setLoading(false);
        fetchData();
      } else {
        Swal.fire("Error", "An error occurred while submitting data.", "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div>
      {loading && <Loader />}
      {editRoom && <EditRoom closeEditRoom={closeEditRoom} item={item} />}
      <h1 className="lg:text-2xl text-lg font-semibold mb-4 text-green-600 mt-4">
        list of rooms assigned to this coldstore.
      </h1>
      <div className="overflow-x-auto">
        <table className="min-w-full border divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border p-2">
                #
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border p-2">
                Room Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border p-2">
                Length
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border p-2">
                Breadth
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border p-2">
                Height
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border p-2">
                Unit
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border p-2">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border p-2">
                Capacity
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border p-2">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {rooms &&
              rooms?.map((room, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">{index + 1}</td>
                  <td className="border px-4 py-2">{room.roomName}</td>
                  <td className="border px-4 py-2">{room.length}</td>
                  <td className="border px-4 py-2">{room.breadth}</td>
                  <td className="border px-4 py-2">{room.height}</td>
                  <td className="border px-4 py-2">{room.unit}</td>
                  <td className="border px-4 py-2">{room.status}</td>
                  <td className="border px-4 py-2">{room.capacity}</td>
                  <td className="border px-4 py-2">
                    <button
                      onClick={() => {
                        setEditRoom(true);
                        setItem(room);
                      }}
                      className="text-blue-500 mr-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(room._id)}
                      className="text-red-500"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewRooms;
