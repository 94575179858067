import React from "react";
import Breadcrumb from "./breadcrumb";
import FormSection from "../formSection";
import TitleCover from "./titleCover";

const Contact = () => {
  return (
    <>
      <TitleCover title="Contact Us Now" />
      <div className="container mx-auto">
        <div className="bg-white py-6">
          <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-8 gap-2 w-[100%]">
            <div
              className="block bg-cover lg:block rounded"
              style={{
                backgroundImage:
                  "url('/image/i15.jpg')",
              }}
            >
              <div className="flex items-center h-full lg:px-20 px-3 bg-gray-900 bg-opacity-70 lg:py-12 py-5 rounded">
                <div>
                  <h2 className="lg:text-3xl text-lg lg:font-bold font-semibold text-white text-center pb-4 pt-4">
                    SEND YOUR QUERIES VIA CONTACT FORM
                  </h2>

                  <p className="lg:mt-3 mt-1 text-white lg:pb-2 pb-1 text-justify lg:tracking-normal tracking-tight">
                    Please feel free to submit your queries through our Contact
                    Form. We're here to assist you and address any questions or
                    concerns you may have. Your inquiries are important to us,
                    and we'll ensure a timely response to provide the
                    information you need. Thank you for considering us as your
                    resource for information and assistance.
                  </p>
                  <div className="w-[100%]">
                    <div className="flex flex-col gap-2 mt-2">
                      <a
                        target="_blank"
                        href="https://www.google.com/maps/place/R+%26+D+Agriculture+Service+Centre/@27.65796,85.397668,19z/data=!4m6!3m5!1s0x39eb107e4a6f7321:0x942767d97617a331!8m2!3d27.6579596!4d85.3976683!16s%2Fg%2F11c5b6jhwl?hl=en&entry=ttu"
                      >
                        <p className="text-base text-gray-100 tracking-wider ">
                          <i className="fas fa-map-marker mr-2 text-[#fff]"></i>
                          R & D Innovative Solution Pvt. Ltd, New Baneshwor,
                          Kathmandu
                        </p>
                      </a>
                      <p className="text-base text-gray-100 tracking-wider">
                        <i className="fas fa-phone mr-2 text-[#fff]"></i>Phone :
                        <a className="" href="tel: +977014795756">
                          01-4795756
                        </a>
                        {"|"}
                        <a className="" href="tel:+9779801084205">
                          +977-9801084205
                        </a>{" "}
                        {"|"}
                        <a className="" href="tel:+9779801084201">
                          +977-9801084201
                        </a>{" "}
                        {"|"}
                        <a className="" href="tel:+9779851106603">
                          +977-9851106603
                        </a>
                        {"|"}
                        <a className="" href="tel:+9779801084204">
                          +977-9801084204
                        </a>
                      </p>
                      <span className="text-base text-gray-100 tracking-wider">
                        <i className="fas fa-envelope mr-2 text-[#fff]"></i>
                        <a href="mailto:coldstorenepal@gmail.com">
                        coldstorenepal@gmail.com
                        </a>
                      </span>
                    </div>
                    {/* <div className="">
                      <div>
                        <p className="text-xl tracking-wider  font-semibold text-gray-100 lg:py-6 py-2">
                          Direct Message :
                        </p>
                        <div className="flex gap-4 shadow text-3xl bg-white px-3 justify-center mb-3 py-2 rounded lg:w-[20%] w-[30%]">
                          <div>
                            <a
                              href="https://wa.me/9808811027?text=Hello!%20I%20am%20contacting%20you%20from%20the%20website."
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button title="whatsapp">
                                <i className="fab fa-whatsapp text-green-600"></i>
                              </button>
                            </a>
                          </div>
                          <div>
                            <a
                              href="viber://forward?text=Hello!%20I%20am%20contacting%20you%20from%20the%20website.&amp;phone=9808811027"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button title="viber">
                                <i className="fab fa-viber text-violet-800"></i>
                              </button>
                            </a>
                          </div>
                          <a
                            target="_blank"
                            href="https://www.facebook.com/bhandariandassociateslawfirm/"
                          >
                            <button title="facebook">
                              <i className="fab fa-weixin text-green-600"></i>
                            </button>
                          </a>
                          <button className="telegram">
                            <i className="fab fa-telegram text-blue-400"></i>
                          </button>
                        </div>
                      </div>
                      <div>
                        <p className="text-xl tracking-wider  font-semibold text-gray-100 lg:py-6 py-2">
                          Follow Us On:
                        </p>
                        <div className="flex gap-4 shadow text-3xl bg-white px-3 justify-center mb-8 py-2 rounded lg:w-[25%] w-[40%]">
                          <a
                            target="_blank"
                            href="https://www.facebook.com/bhandariandassociateslawfirm/"
                          >
                            <button title="facebook">
                              <i className="fab fa-facebook-square text-blue-500"></i>
                            </button>
                          </a>
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/company/bhandari-associates-law-firm/"
                          >
                            <button title="linkedin">
                              <i className="fab fa-linkedin text-blue-800"></i>
                            </button>
                          </a>
                          <a
                            target="_blank"
                            href="https://www.instagram.com/bhandari_law/?igshid=MzNlNGNkZWQ4Mg%3D%3D"
                          >
                            <button title="instagram">
                              <i className="fab fa-instagram-square text-pink-600"></i>
                            </button>
                          </a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <FormSection />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
