import React, { useEffect } from "react";
import Slider from "../../components/site/slider";
import About from "../../components/site/about";
import Services from "../../components/site/services";
import Project from "../../components/site/project";
import Gallery from "../../components/site/gallery";
import Tab from "../../components/site/tab";
import Blog from "../../components/site/blog";
import Contact from "../../components/site/contact";
import axios from "axios";

const HomePage = () => {
  // useEffect(() => {
  //   const hitRoot = async () => {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BASE_API_URL}/api/root/sensor/get-data`
  //     );
  //     console.log(response);
  //   };
  //   hitRoot();
  // }, []);
  return (
    <div>
      <Slider />
      <About />
      <Services />
      <Project />
      <Gallery />
      <Tab />
      {/* <Blog /> */}
      <Contact />
    </div>
  );
};

export default HomePage;
