import React from "react";
import TitleCover from "./titleCover";
import ServiceCard from "./cards/serviceCard";

const Services = () => {
  const services = [
    {
      img: "/image/i16.jpg",
      title: "Cold Store Management",
      description:
        "We help you manage your cold store by keeping record of your goods. Login to our website and keep the record of your commodities. Its completely free! <br/>As, for our premium service, you need to buy our device that tracks the temperature and moisture condition of your cold chamber. We monitor the status of your cold storage with the help of that. After that, you dont need to worry anything about your cold storage.",
      slug: "cold-store-management",
    },
    {
      img: "/image/i12.jpg",
      title: "Cold Chamber Maintenance",
      description:
        "We also provide the facility of cold chamber maintenance, if any problems occur.",
      slug: "cold-chamber-maintenance",
    },
    {
      img: "/image/i9.jpg",
      title: "Cold chamber/warehouse set-up",
      description:
        "We also provide the facility of cold chamber maintenance, if any problems occur.",
      slug: "cold-chamber-warehouse-set-up",
    },
  ];
  return (
    <div className="py-5">
      <TitleCover title="Our Services" />
      <div className="grid grid-cols-1 gap-6 py-4 container mx-auto">
        {services.map((item, i) => (
          <ServiceCard i={i} service={item} />
        ))}
      </div>
    </div>
  );
};

export default Services;
