import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
const District = ({submitState,toggleState}) => {
  const [provinces, setProvinces] = useState([]);
  const [provinceId, setProvinceId] = useState("");
  const [name, setName] = useState("");
  const [state, setState] = useState(false);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    const fetchDistricts = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/location/district/get-all`
      );
      setDistricts(response.data);
    };
    fetchDistricts();
  }, [state]);

  useEffect(() => {
    const fetchProvinces = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/location/province/get-all`
      );
      setProvinces(response.data);
    };
    fetchProvinces();
  }, [state,submitState]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/location/district/add`,
      {
        name,
        provinceId,
      }
    );
    if (response.data.message) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: response.data.message,
      });
      setName("");
      setProvinceId("");
      setState(!state);
      toggleState()
    }
  };
  const deleteDistrict = async (districtId) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_API_URL}/api/location/district/delete/${districtId}`
    );
    if (response.data.message) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: response.data.message,
      });
      setState(!state);
      toggleState()
    }
  };
  return (
    <div className="p-4 shadow">
      <p className="text-xl font-semibold py-2 px-3 font-serif">District</p>
      <div>
        <select
          onChange={(e) => setProvinceId(e.target.value)}
          value={provinceId}
          className="px-4 py-2 w-[80%]"
        >
          <option value="">select province</option>
          {provinces &&
            provinces.map((item, i) => (
              <option key={i} value={item._id}>
                {item.name}
              </option>
            ))}
            
        </select>
        <div className="mb-5 mt-3">
          <label
            for="district"
            className="mb-3 block text-base font-medium text-[#07074D]"
          >
            district
          </label>
          <input
            type="text"
            name="district"
            id="name"
            placeholder="Enter district name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          />
        </div>
        <div>
          <button
            onClick={handleSubmit}
            className="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-base font-semibold text-white outline-none float-right"
          >
            Submit
          </button>
        </div>
      </div>
      <div>
        <p className="text-sm text-gray-600 font-semibold py-2 px-3 font-serif">
        lists of district with respect to thier province.
        </p>
        <div className="flex flex-col gap-4 mt-4">
          {districts &&
            districts.map((item, i) => (
              <div key={i} className="flex justify-between gap-2">
                <p>{item.name}</p>
                <p>{item.provinceId?.name}</p>
                <button
                  onClick={() => deleteDistrict(item._id)}
                  className="bg-red-500 text-white px-2 py-1 rounded
                "
                >
                  Delete
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default District;
