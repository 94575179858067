import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import handleApiRequest from "../../libs/apiHandler";
import Loader from "../loader";

const EditAssignProduct = ({ closeAssignProduct, item }) => {
  const [roomId, setRoomId] = useState(item.roomId);
  const [storageType, setStorageType] = useState(item.storageType);
  const [numberOfContainers, setNumberOfContainers] = useState(
    item.numberOfContainers
  );
  const [dateOfStorage, setDateOfStorage] = useState(item.dateOfStorage);
  const [productId, setProductId] = useState(item.productId);
  const [products, setProducts] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [myData] = useState(item);

  useEffect(() => {
    const originalDate = new Date(dateOfStorage);
    const formattedDate = originalDate.toISOString().split('T')[0];
    setDateOfStorage(formattedDate);
    const fetchData = async () => {
      try {
        const targetPath = `room/get-user-room/${item.coldstoreId}`;
        const result = await handleApiRequest("GET", targetPath, null, true);
        setRooms(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const targetPath = `product/get`;
        const result = await handleApiRequest("GET", targetPath, null, false);
        setProducts(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchProduct();
  }, []);

  const handleEdit = async (e) => {
    e.preventDefault();
    const data = {
      roomId,
      storageType,
      numberOfContainers,
      dateOfStorage,
      coldstoreId: item.coldstoreId,
      productId,
    };
    setLoading(true);
    try {
      const targetPath = `coldstore/update-assign-product/${item?._id}`;
      const result = await handleApiRequest("PUT", targetPath, data, true);
      if (result.message) {
        Swal.fire("Success", result.message, "success");
        setLoading(false);
        closeAssignProduct();
      } else {
        Swal.fire("Error", "An error occurred while submitting data.", "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="fixed top-0 bg-[#ffffff5f] pt-4 h-screen w-full inset-0">
      {loading && <Loader />}

      <div className="max-w-2xl mx-auto bg-gray-600 p-4">
        <button
          onClick={closeAssignProduct}
          className="bg-red-500 rounded px-2 py-1 text-gray-600 float-right"
        >
          <i className="fas fa-times"></i>
        </button>
        <form
          className="max-w-xl mx-auto shadow p-8 rounded text-gray-600 grid grid-cols-2 gap-2"
          onSubmit={handleEdit}
        >
          <div className="mb-4">
            <label htmlFor="product" className="block font-medium mb-1">
              Select Product:
            </label>
            <select
              value={productId}
              onChange={(e) => setProductId(e.target.value)}
              id="product"
              className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
              required
            >
              <option value="">Select product</option>
              {products.map((item, i) => (
                <option
                  key={i}
                  value={productId}
                  required={myData.productId === item._id ? true : false}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="product" className="block font-medium mb-1">
              Select Rooms:
            </label>
            <select
              value={roomId}
              onChange={(e) => setRoomId(e.target.value)}
              id="room"
              className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
              required
            >
              <option value="">Select room</option>
              {rooms.map((item) => (
                <option
                  key={item._id}
                  value={myData.roomId}
                  selected={myData.roomId === item._id ? true : false} // Fix here
                >
                  {item.roomName}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="storageType" className="block font-medium mb-1">
              Storage Type:
            </label>
            <select
              id="storageType"
              className="w-full border border-gray-300 rounded px-3 py-2"
              value={storageType}
              onChange={(e) => setStorageType(e.target.value)}
              required
            >
              <option value="">Select Storage Type</option>
              <option
                selected={
                  myData.storageType === item.storageType ? true : false
                }
                value="crate"
              >
                Crate
              </option>
              <option value="sacks">Sacks</option>
            </select>
          </div>

          <div className="mb-4">
            <label
              htmlFor="numberOfContainers"
              className="block font-medium mb-1"
            >
              Number of Containers:
            </label>
            <input
              type="number"
              id="numberOfContainers"
              className="w-full border border-gray-300 rounded px-3 py-2"
              value={numberOfContainers}
              onChange={(e) => setNumberOfContainers(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="dateOfStorage" className="block font-medium mb-1">
              Date of Storage:
            </label>
            <input
              type="date"
              id="dateOfStorage"
              className="w-full border border-gray-300 rounded px-3 py-2"
              value={dateOfStorage}
              onChange={(e) => setDateOfStorage(e.target.value)}
              required
            />
          </div>

          <button
            type="submit"
            className="bg-green-500 text-white rounded px-4 py-2 hover:bg-green-600 col-span-2"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditAssignProduct;
