import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

const ApiKeyTable = ({state}) => {
  const [apiKeys, setApiKeys] = useState([""]);
  const fetchApiKeys = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/apikey/get_all`);
      setApiKeys(response.data);
    } catch (error) {
      console.log("Error fetching API keys:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/api/apikey/delete/${id}`
      );
      if (response.data.message) {
        Swal.fire({
          icon: "success",
          title: "API Key Deleted!",
          text: response.data.message,
        });
        fetchApiKeys();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while deleting the API key. Please try again later.",
      });
    }
  };

  useEffect(() => {
    fetchApiKeys();
  }, [state]);

  return (
    <div className="p-6 bg-white rounded-lg shadow-md mt-4">
      <p className="py-4 text-lg font-semibold">Api Key List</p>
      <table className="w-full table-auto">
        <thead>
          <tr className="text-left">
            <th className="px-4 py-2">API Key</th>
            <th className="px-4 py-2">User</th>
            <th className="px-4 py-2">Status</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {apiKeys &&
            apiKeys.map((apiKey) => (
              <tr key={apiKey._id}>
                <td className="border px-4 py-2">{apiKey.apikey}</td>
                <td className="border px-4 py-2">
                  <p>{apiKey?.user?.fullName}</p>
                  <p>{apiKey?.user?.email}</p>
                </td>
                <td className="border px-4 py-2">{apiKey.status}</td>
                <td className="border px-4 py-2">
                  {/* <button
                    className="bg-blue-500 text-white px-2 py-1 rounded-md mr-2"
                      onClick={() => handleEdit(apiKey._id)}
                  >
                    Edit
                  </button> */}
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded-md"
                    onClick={() => handleDelete(apiKey._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

const ApiKey = () => {
  const [apikey, setApikey] = useState("");
  const [user, setUser] = useState("");
  const [userList, setUserList] = useState([""]);
  const [status, setStatus] = useState("active");
  const [state,setState] = useState(true);
  useEffect(() => {
    try {
      const fetchUsers = async () => {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/api/user`
        );
        setUserList(response.data);
      };
      fetchUsers();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/apiKey/add`, {
        apikey,
        user,
        status,
      });
      if (response.data.message) {
        Swal.fire({
          icon: "success",
          title: "API Key Created!",
          text: `${response.data.message}`,
        });
        setApikey("");
        setStatus("");
        setUser("");
        setState(!state);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while creating the API key. Please try again later.",
      });
    }
  };
  return (
    <div className="p-4">
      <div className="p-6 bg-white rounded-lg shadow-md">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="apikey"
              className="block text-gray-700 font-bold mb-2"
            >
             Add Api Key
            </label>
            <input
              type="text"
              id="apikey"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              value={apikey}
              onChange={(e) => setApikey(e.target.value)}
              required
            />
          </div>
          <div className="">
            <label
              for="coldstore name"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              assign to user
            </label>
            <select
              required
              value={user}
              onChange={(e) => setUser(e.target.value)}
              className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              name="user"
              id="user"
            >
              <option>select user</option>
              {userList.map((item, i) => (
                <option value={item._id} key={i}>
                  {item.fullName}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-4">
            <label
              for="status"
              className="mb-3 block text-base font-medium text-[#07074D]"
            >
              Api status
            </label>
            <div className="mb-4">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  value="active"
                  checked={status === "active"}
                  onChange={(e) => setStatus(e.target.value)}
                  className="form-checkbox h-5 w-5 text-indigo-600"
                />
                <span className="ml-2">Active</span>
              </label>
            </div>
            <div className="mb-4">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  value="disabled"
                  checked={status === "disabled"}
                  onChange={(e) => setStatus(e.target.value)}
                  className="form-checkbox h-5 w-5 text-indigo-600"
                />
                <span className="ml-2">disabled</span>
              </label>
            </div>
          </div>
          <div className="mb-4 mt-4">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
            >
              Create API Key
            </button>
          </div>
        </form>
      </div>
      <ApiKeyTable state={state} />
    </div>
  );
};

export default ApiKey;
