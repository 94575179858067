import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "../components/loader";
import { Link } from "react-router-dom";

const Signup = () => {
  const [errors, setErrors] = useState([""]);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const formData = {
    fullName,
    email,
    password,
    confirmPassword,
    officeName,
    phone,
  };

  const handleSubmit = async (event) => {
    try {
      setloading(true);
      event.preventDefault();
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/user/signup`,
        formData
      );
      if (response.data.message) {
        Swal.fire({
          icon: "success",
          text: `${response.data.message}`,
          footer: '<a href="https://mail.google.com/">Visit mail</a>',
        });
        setloading(false);
      }
      setErrors(response.data.errors);
      setloading(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="lg:pt-8 pt-2 bg-slate-200 pb-8">
      <div className="container mx-auto">
        <div className="flex justify-center lg:px-6 px-2">
          <div className="w-full xl:w-3/4 lg:w-[100%] flex shadow rounded">
            <div
              className="w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg"
              style={{
                backgroundImage: "url('/assets/s2.jpg')",
              }}
            ></div>
            <div className="w-full lg:w-[60%] bg-white lg:p-5 p-2 rounded-lg lg:rounded-l-none">
              <h3 className="text-xl text-center text-gray-600">
                Register your account!
              </h3>
              <p className="text-sm text-gray-600 mt-4 lg:block">
                Please fill out the form to resgister your account, and we will
                contact you for any further inquiries.
              </p>
              <form
                onSubmit={handleSubmit}
                className="lg:px-8 pt-6 bg-white rounded w-full grid grid-cols-2 gap-3 space-y-2"
              >
                <div className="mt-2">
                  <label
                    className="block mb-2 text-sm font-semibold text-gray-700"
                    for="fullName"
                  >
                    Enter your name*
                  </label>
                  <input
                    className="w-full px-3 py-3 text-sm leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
                    id="firstName"
                    type="text"
                    name="fistName"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    placeholder="Enter your full name"
                  />
                </div>
                <div className="">
                  <label
                    className="block mb-2 text-sm font-semibold text-gray-700"
                    for="officeName"
                  >
                    Name of Office {"(optional)"}
                  </label>
                  <input
                    className="w-full px-3 py-3 text-sm leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
                    id="officeName"
                    type="text"
                    name="officeName"
                    value={officeName}
                    onChange={(e) => setOfficeName(e.target.value)}
                    placeholder="Name of the office"
                  />
                </div>

                <div className="mb-1">
                  <label
                    className="block mb-2 text-sm font-semibold text-gray-700"
                    for="email"
                  >
                    Email*
                  </label>
                  <input
                    className="w-full px-3 py-3 text-sm leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mb-1">
                  <label
                    className="block mb-2 text-sm font-semibold text-gray-700"
                    for="phoneNumber"
                  >
                    Phone Number*
                  </label>
                  <input
                    className="w-full px-3 py-3 text-sm leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
                    id="phoneNumber"
                    type="text"
                    name="phoneNumber"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Enter your phone number"
                  />
                </div>

                <div className="">
                  <label
                    className="block mb-2 text-sm font-semibold text-gray-700"
                    for="password"
                  >
                    Password*
                  </label>
                  <input
                    className="w-full px-3 py-3 text-sm leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
                    id="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="md:ml-2">
                  <label
                    className="block mb-2 text-sm font-semibold text-gray-700"
                    for="c_password"
                  >
                    Confirm Password*
                  </label>
                  <input
                    className="w-full px-3 py-3 text-sm leading-tight text-gray-700 border rounded shadow-sm focus:outline-none focus:shadow-outline"
                    id="c_password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Re-enter your password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>

                <div className="mb-3 col-span-2">
                  <p
                    onClick={handleTogglePassword}
                    className="bg-green-500 rounded text-gray-100 px-2 py-1 lg:w-[20%] w-[32%] text-xs cursor-pointer"
                  >
                    {showPassword ? "Hide Password" : "Show Password"}
                  </p>
                </div>
                <div className="mb-2 text-center col-span-2">
                  <button
                    className="w-full px-4 py-2 font-semibold text-white bg-green-500 rounded hover:bg-green-700 focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Register Account
                  </button>
                </div>
                {errors && (
                  <div className="flex flex-col gap-1 col-span-2">
                    {errors &&
                      errors.map((error, i) => (
                        <div key={i}>
                          <p className="text-red-400 text-sm font-semibold leading-6">
                            {error.message}.
                          </p>
                        </div>
                      ))}
                  </div>
                )}
              </form>

              <hr className="border-t mb-4" />
              <p className="text-sm">
                Already have an account.?{" "}
                <Link to="/signin">Click here to login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default Signup;
