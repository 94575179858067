import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import AddUserForm from "./addUserForm";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import EditUser from "./editUser";

const UserList = () => {
  const [userList, setUserList] = useState([]);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [update, setUpdate] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [user, setUser] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [editUser, setUserEdit] = useState(false);
  useEffect(() => {
    try {
      const fetchUsers = async () => {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/api/user`
        );
        setUserList(response.data);
      };
      fetchUsers();
    } catch (error) {
      console.log(error);
    }
  }, [update]);

  const closeAddUserForm = () => {
    setShowAddUserForm(false);
  };

  const toggleState = () => {
    setUpdate(!update);
  };

  const deleteUser = async (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const token = Cookies.get("user-token")
          ? Cookies.get("user-token")
          : null;
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_API_URL}/api/user/delete/${userId}`,
            {
              headers: {
                "user-token": token,
              },
            }
          );
          if (response.data.message) {
            Swal.fire("Deleted!", "User has been deleted.", "success");
            setUpdate(!update);
          }
        }
      }
    });
  };

  // Search functionality
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset current page to 1 when searching
  };

  // Pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const filteredUsers = userList.filter((user) =>
    user?.fullName?.toLowerCase().includes(searchTerm?.toLowerCase())
  );
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="w-full rounded-lg border border-gray-200 shadow-md">
      <nav
        className="flex px-5 py-3 text-gray-700 bg-gray-50 dark:bg-[#1E293B] "
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <Link
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
              to="/"
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6.293 7.293a1 1 0 0 1 0 1.414L3.414 12H16a1 1 0 0 1 0 2H3.414l2.879 2.879a1 1 0 1 1-1.414 1.414l-4.586-4.586a1 1 0 0 1 0-1.414L6.293 7.293zM13 6a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1zm-2-4a1 1 0 0 1 2 0v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0V6H9a1 1 0 0 1 0-2h2V2z"
                />
              </svg>
              Home
            </Link>
          </li>
          <li className="inline-flex items-center">
            <Link
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
              to="/users"
            >
              Users
            </Link>
          </li>
        </ol>
        <div className="ml-auto">
          <button
            className="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-500 border border-transparent rounded-md active:bg-green-600 hover:bg-green-600 focus:outline-none focus:shadow-outline-green"
            onClick={() => setShowAddUserForm(true)}
          >
            Add User
          </button>
        </div>
      </nav>
      <div className="p-5 w-full">
        <div className="flex justify-between items-center">
          <div className="relative w-48">
            <input
              type="text"
              className="w-full pl-10 pr-4 py-2 rounded-lg border-gray-200 focus:border-blue-500 focus:outline-none"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
            />
            <div className="absolute top-0 left-0 mt-2 ml-3">
              <svg
                className="w-4 h-4 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 15l-2.807-2.807M9 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="w-full lg:overflow-hidden overflow-x-scroll">
          <table className="w-full divide-y divide-gray-200 mt-4 lg:overflow-hidden overflow-x-auto">
            <thead className="bg-gray-50 dark:bg-[#1E293B]">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  S.N
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Client Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Phone Number
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Office Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentUsers.map((user, i) => (
                <tr key={i}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{i + 1}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="">
                        <div className="text-sm font-medium text-gray-900">
                          {user.fullName}
                        </div>
                        <div className="text-sm text-gray-500">
                          {user.email}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{user.phone}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {user.officeName}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    {/* <button className="text-indigo-600 hover:text-indigo-900 mr-2">
                      View
                    </button> */}

                    {user.role != "admin" && (
                      <button
                        className="text-indigo-600 hover:text-indigo-900 mr-2"
                        onClick={() => deleteUser(user._id)}
                      >
                        Delete
                      </button>
                    )}
                    {/* <button
                      onClick={() => {
                        setUserEdit(true);
                        setUser(user);
                      }}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Edit
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4">
          <nav
            className="flex items-center justify-between"
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium">
                  {indexOfFirstUser + 1}-
                  {Math.min(indexOfLastUser, filteredUsers.length)}
                </span>{" "}
                of <span className="font-medium">{filteredUsers.length}</span>{" "}
                results
              </p>
            </div>
            <div className="flex-1 flex justify-between sm:justify-end">
              <button
                className={`${
                  currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
                } relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 text-sm font-medium text-gray-500 bg-white hover:bg-gray-50`}
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.707 5.293a1 1 0 0 1 0 1.414L7.414 10l3.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <button
                className={`${
                  currentPage === totalPages
                    ? "cursor-not-allowed opacity-50"
                    : ""
                } ml-3 relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 text-sm font-medium text-gray-500 bg-white hover:bg-gray-50`}
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <span className="sr-only">Next</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.293 14.707a1 1 0 0 1 0-1.414L12.586 10l-3.293-3.293a1 1 0 0 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </nav>
        </div>
      </div>
      {showAddUserForm && (
        <AddUserForm
          closeAddUserForm={closeAddUserForm}
          toggleState={toggleState}
        />
      )}
      {editUser && <EditUser user={user} />}
    </div>
  );
};

export default UserList;
