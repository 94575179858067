import React from "react";
import TitleCover from "./titleCover";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination,Autoplay } from "swiper/modules";

const Project = () => {
  return (
    <div className="">
      <TitleCover title="Our Clients" />
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true} // Enable looping
        autoplay={{ delay: 3000, disableOnInteraction: false }} // Enable autoplay with a 3-second delay
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div>
            <img src="/assets/s1.jpg" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src="/assets/s2.jpg" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src="/assets/s3.jpg" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src="/assets/s1.jpg" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src="/assets/s2.jpg" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src="/assets/s3.jpg" alt="" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Project;
