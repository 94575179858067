import React from 'react'
import Breadcrumb from '../../components/site/breadcrumb'
import Gallery from '../../components/site/gallery'

const GalleryPage = () => {
  return (
    <div>
        <Breadcrumb page="Gallery"/>
        <Gallery/>
    </div>
  )
}

export default GalleryPage