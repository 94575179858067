import React, { useState } from "react";
import Swal from "sweetalert2";
import Loader from "./loader";
import handleApiRequest from "../libs/apiHandler";

const Rooms = ({ coldstore, closeRoomForm }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    coldstoreId: coldstore?._id,
    roomName: "",
    length: "",
    breadth: "",
    height: "",
    unit: "feet",
    status: "active",
    capacity: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const calculateCapacity = () => {
    const { length, breadth, height } = formData;
    const mt =
      0.7 * parseFloat(length) * parseFloat(breadth) * parseFloat(height);
    return isNaN(mt) ? "" : mt.toFixed(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const targetPath = `room/add`;
      const result = await handleApiRequest("POST", targetPath, formData, true);
      if (result.message) {
        Swal.fire("Success", result.message, "success");
        setLoading(false);
        closeRoomForm();
      } else {
        Swal.fire("Error", "An error occurred while submitting data.", "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire("Error", "An error occurred while submitting data.", "error");
    }
  };

  return (
    <div className="p-4 fixed top-0 inset-0 z-[999] bg-[#ffffff5d]">
      {loading && <Loader />}
      <div className="max-w-lg mx-auto bg-white rounded p-6 shadow-md">
        <button
          onClick={closeRoomForm}
          className="float-right bg-green-600 text-gray-100 px-2 py-1 rounded"
        >
          close
        </button>
        <p className="text-lg text-gray-600 font-semibold py-4">
          Create room for coldstore {coldstore.name}
        </p>
        <form className="grid grid-cols-2 gap-2" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="roomname"
            >
              Room Name
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              type="text"
              id="roomName"
              name="roomName"
              value={formData.roomName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="length"
            >
              Length
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              type="number"
              id="length"
              name="length"
              value={formData.length}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="breadth"
            >
              Breadth
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              type="number"
              id="breadth"
              name="breadth"
              value={formData.breadth}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="height"
            >
              Height
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              type="number"
              id="height"
              name="height"
              value={formData.height}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="unit"
            >
              Unit
            </label>
            <select
              className="w-full px-3 py-2 border rounded"
              id="unit"
              name="unit"
              value={formData.unit}
              onChange={handleChange}
            >
              <option value="feet">Feet</option>
              <option value="metre">Metre</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="status"
            >
              Status
            </label>
            <select
              className="w-full px-3 py-2 border rounded"
              id="status"
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="capacity"
            >
              Capacity
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              type="text"
              id="capacity"
              name="capacity"
              value={calculateCapacity()}
              readOnly
            />
          </div>
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded col-span-2"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Rooms;
