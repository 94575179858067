import React from "react";

const FaqItem = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="group flex flex-col gap-2   text-gray-600 shadow mb-4">
      <div
        className="flex cursor-pointer items-center justify-between bg-gray-100 p-5 rounded-lg"
        onClick={toggleExpand}
      >
        <span className="lg:text-xl text-lg">{question}</span>
        {isExpanded ? <p>-</p> : <p>+</p>}
      </div>
      {isExpanded && (
        <div className="max-h-screen opacity-100 duration-1000 lg:text-base text-sm bg-green-800 p-5 rounded-lg text-gray-50 opacity-100">
          {answer}
        </div>
      )}
    </div>
  );
};

const Faq = () => {
  const faqData = [
    {
      question: "Is coolbot technology for small scale only?",
      answer:
        "No, coolbot-technology is not for small scale only. It can be used for any size cold storage or warehouse. It is available for any required size.",
    },
    {
      question: "What is the operating cost of coolbot cold storage?",
      answer:
        "The operating cost of coolbot cold storage is very less compared to traditional cold store technology. Basically, the only operating cost is the electricity charge.",
    },
    {
      question:
        "Can we store different crops of similar chilling temperature together?",
      answer:
        "It is preferred to store different goods separately. Two different goods can't be stored together. The smell, gas, and other various factors released by one good can have a harmful effect on other goods resulting in the damage of goods. So it is best not to store two different goods together.",
    },
    {
      question:
        "Can we store different goods over different periods of time in the same cold room?",
      answer:
        "Yes, we can store different goods over different periods of time in the same cold room. Once one good is taken out, another type of good can be stored. Only one type of good is stored at one time in one cold room.",
    },
    {
      question: "Can we store different types of goods in separate cold rooms?",
      answer:
        "Yes, we can store different goods in different cold rooms separately. Note that, we can store only one type of good in one cold room.",
    },
    {
      question:
        "How much does it cost to establish a cold storage chamber in Nepal?",
      answer:
        "Depending on its size, a cold store chamber will vary in price. For the size of the cold store, it is better to consult with a technical person. Contact us, and we'll help you with all the technical details and estimate the cost of setting up a cold chamber.",
    },
    // Add more FAQ items here
  ];

  return (
    <div className="container mx-auto">
      <div className="w-full text-xl">
        {faqData.map((faqItem, index) => (
          <FaqItem
            key={index}
            question={faqItem.question}
            answer={faqItem.answer}
          />
        ))}
      </div>
    </div>
  );
};

export default Faq;
