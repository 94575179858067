import React, { useState } from "react";
import TitleCover from "./titleCover";

const Gallery = () => {
  const [zoomview, setZoomView] = useState(false);
  const [zoomImgUrl, setZoomImgUrl] = useState("");

  const zoomImage = (imgUrl) => {
    setZoomView(true);
    setZoomImgUrl(imgUrl);
  };

  return (
    <div>
      <TitleCover title="Gallery" />
      {zoomview && (
        <div className="fixed inset-0 bg-slate-50 top-2 left-0 overflow-y-auto z-[999]">
          <div className="w-[70%] h-[100%] rounded mx-auto">
            <button
              onClick={() => setZoomView(false)}
              className="float-right text-xl h-[30px] w-[30px] bg-red-600 text-white"
            >
              <i className="fa fa-times"></i>
            </button>
            <img
              class="h-[90vh] w-[100%] object-cover rounded pt-2 "
              src={zoomImgUrl}
              alt=""
            />
          </div>
        </div>
      )}
      <div class="container mx-auto p-4">
        <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
          <div class="grid gap-4">
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i1.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i1.jpg");
                }}
              />
            </div>
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i2.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i2.jpg");
                }}
              />
            </div>
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i3.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i3.jpg");
                }}
              />
            </div>
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i13.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i13.jpg");
                }}
              />
            </div>
          </div>
          <div class="grid gap-4">
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i4.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i4.jpg");
                }}
              />
            </div>
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i5.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i5.jpg");
                }}
              />
            </div>
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i6.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i6.jpg");
                }}
              />
            </div>
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i14.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i14.jpg");
                }}
              />
            </div>
          </div>
          <div class="grid gap-4">
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i7.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i7.jpg");
                }}
              />
            </div>
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i8.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i8.jpg");
                }}
              />
            </div>
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i9.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i9.jpg");
                }}
              />
            </div>
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i15.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i15.jpg");
                }}
              />
            </div>
          </div>
          <div class="grid gap-4">
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i10.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i10.jpg");
                }}
              />
            </div>

            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i11.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i11.jpg");
                }}
              />
            </div>
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i12.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i12.jpg");
                }}
              />
            </div>
            <div>
              <img
                class="h-[300px] w-[100%] object-cover rounded-lg"
                src="/image/i16.jpg"
                alt=""
                onClick={() => {
                  zoomImage("/image/i16.jpg");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
