import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Signup from "./pages/signup";
import Login from "./pages/login";
import AdminDashboard from "./pages/admin/dashboard";
import UserDashboard from "./pages/user/dashboard";
import ActivateUser from "./pages/activateUser";
import HomePage from "./pages/site/homePage";
import React, { useEffect, useState } from "react";
import AdminLayout from "./components/admin/adminLayout";
import UserLayout from "./components/user/userLayout";
import UserListPage from "./pages/admin/user_list";
import Coldstore from "./pages/admin/coldstore";
import UserColdstore from "./pages/user/coldstore";
import Product from "./pages/admin/product";
import Location from "./pages/admin/location";
import ApiKey from "./components/admin/apiKey";
import SiteLayout from "./components/siteLayout";
import AboutPage from "./pages/site/aboutPage";
import ServicePage from "./pages/site/servicePage";
import ProjectPage from "./pages/site/projectPage";
import GalleryPage from "./pages/site/galleryPage";
import TeamPage from "./pages/site/teamPage";
import ServiceDetails from "./pages/site/service-details";
import Blog from "./components/site/blog";
import BlogDetails from "./pages/site/blog-details";
import FaqsPage from "./pages/site/faqsPage";
import ContactPage from "./pages/site/contactPage";
import BlogPage from "./pages/site/blogPage";

function App() {
  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    setCurrentUrl(window.location.href);
    console.log(currentUrl);
  }, []);

  return (
    <Router>
      {/* {currentUrl === `${process.env.REACT_APP_CLIENT_URL}` && <Header />} */}
      <Routes>
        <Route
          exact
          path="/"
          element={
            <SiteLayout>
              <HomePage />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="/about"
          element={
            <SiteLayout>
              <AboutPage />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="/services"
          element={
            <SiteLayout>
              <ServicePage />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/service/:slug"
          element={
            <SiteLayout>
              <ServiceDetails />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="/notice-and-faqs"
          element={
            <SiteLayout>
              <TeamPage />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="/blog"
          element={
            <SiteLayout>
              <BlogPage />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/blog/:slug"
          element={
            <SiteLayout>
              <BlogDetails />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="/gallery"
          element={
            <SiteLayout>
              <GalleryPage />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="/project"
          element={
            <SiteLayout>
              <ProjectPage />
            </SiteLayout>
          }
        />
      </Routes>

      <Routes>
        <Route
          exact
          path="/contact"
          element={
            <SiteLayout>
              <ContactPage />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/signup"
          element={
            <SiteLayout>
              <Signup />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/signin"
          element={
            <SiteLayout>
              <Login />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/admin/dashboard"
          element={
            <AdminLayout>
              <AdminDashboard />
            </AdminLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/admin/dashboard/user-list"
          element={
            <AdminLayout>
              <UserListPage />
            </AdminLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/admin/coldstore"
          element={
            <AdminLayout>
              <Coldstore />
            </AdminLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/admin/product"
          element={
            <AdminLayout>
              <Product />
            </AdminLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/admin/location"
          element={
            <AdminLayout>
              <Location />
            </AdminLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/admin/api_key"
          element={
            <AdminLayout>
              <ApiKey />
            </AdminLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/user/dashboard"
          element={
            <UserLayout>
              <UserDashboard />
            </UserLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/user/coldstore"
          element={
            <UserLayout>
              <UserColdstore />
            </UserLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/activate-account/:token"
          element={
            <SiteLayout>
              <ActivateUser />
            </SiteLayout>
          }
        />
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
