import React from "react";
import Breadcrumb from "../../components/site/breadcrumb";
import Blog from "../../components/site/blog";

const BlogPage = () => {
  return (
    <div>
      <Breadcrumb page="Blog" />
      <div className="container mx-auto">
        <Blog />
      </div>
    </div>
  );
};

export default BlogPage;
