import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";

import htmr from "htmr";
const Product = () => {
  const [products, setProducts] = useState([""]);
  const [update, setUpdate] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [productForm, setProductForm] = useState(false);
  const description_editorRef = useRef(null);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);
    if (description_editorRef.current) {
      formData.append(
        "description",
        description_editorRef.current.getContent()
      );
    }
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/product/add`,
      formData,
      {
        headers: {
          "user-token": Cookies.get("user-token"),
        },
      }
    );
    if (response.data.success) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: response.data.message,
      });
      setUpdate(!update);
      toggleForm();
      window.location.replace("/user/coldstore");
    }
  };

  useEffect(() => {
    const fetchProuct = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/product/get`
      );
      setProducts(response.data);
    };
    fetchProuct();
  }, [update]);

  const deleteProduct = async (productId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/api/product/delete/${productId}`,
      {
        headers: {
          "user-token": Cookies.get("user-token"),
        },
      }
    );
    if (response.data.success) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: response.data.message,
      });
      setUpdate(!update);
      window.location.replace("/user/coldstore");
    }
  };

  const toggleForm = () => {
    setProductForm(!productForm);
  };

  return (
    <div className="h-screen overflow-scroll">
      <nav
        className="flex px-5 py-3 text-gray-700 bg-gray-50 dark:bg-[#1E293B] "
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
              <svg
                className="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              Home
            </Link>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <Link className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                product
              </Link>
            </div>
          </li>
        </ol>
      </nav>
      <div className="p-4">
        <button
          onClick={toggleForm}
          className="bg-green-700 hover:bg-green-900 text-gray-100 px-2 py-1 rounded">
          {productForm ? "close" : "Add Product"}
        </button>
      </div>
      {productForm && (
        <div className="flex items-center justify-center p-12">
          <div className="mx-auto w-full max-w-[550px] shadow p-4">
            <form action="https://formbold.com/s/FORM_ID" method="POST">
              <div className="mb-5">
                <label
                  for="subject"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Product name
                </label>
                <input
                  type="text"
                  name="subject"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id="subject"
                  placeholder="Enter your subject"
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>
              <div className="mb-5">
                <label
                  for="message"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Product description
                </label>
                <Editor
                  onInit={(evt, editor) =>
                    (description_editorRef.current = editor)
                  }
                  initialValue=""
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
              </div>
              <div className="mb-5">
                <label
                  for="message"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Product image
                </label>
                <input
                  onChange={(e) => setImage(e.target.files[0])}
                  type="file"
                  className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>
              <div>
                <button
                  onClick={handleSubmit}
                  className="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-base font-semibold text-white outline-none"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <table className="min-w-full border-collapse block md:table">
        <thead className="block md:table-header-group">
          <tr className="border border-grey-500 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto  md:relative ">
            <th className="bg-gray-200 p-2 text-gray-600 font-bold md:border md:border-grey-500 text-left block md:table-cell">
              Name
            </th>
            <th className="bg-gray-200 p-2 text-gray-600 font-bold md:border md:border-grey-500 text-left block md:table-cell">
              Image
            </th>
            <th className="bg-gray-200 p-2 text-gray-600 font-bold md:border md:border-grey-500 text-left block md:table-cell">
              Date
            </th>
            <th className="bg-gray-200 p-2 text-gray-600 font-bold md:border md:border-grey-500 text-left block md:table-cell">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="block md:table-row-group">
          {products &&
            products.map((item, i) => (
              <tr
                key={i}
                className="bg-white border border-grey-500 md:border-none block md:table-row"
              >
                <td className="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                  <span className="inline-block w-1/3 md:hidden font-bold">
                    Name
                  </span>
                  {item.name}
                </td>
                <td className="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                  <img
                    src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${item.image}`}
                    alt={item.name}
                    className="w-[10rem] h-[8rem]"
                  />
                </td>
                <td className="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                  {item && htmr(item?.createdAt)}
                </td>
                <td className="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                  <span className="inline-block w-1/3 md:hidden font-bold">
                    Actions
                  </span>
                  {/* <button className="bg-blue-500 mr-2 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-500 rounded">
                    Edit
                  </button> */}
                  <button
                    onClick={() => deleteProduct(item._id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 border border-red-500 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Product;
