import React from "react";
import Breadcrumb from "../../components/site/breadcrumb";
import Team from "../../components/site/team";
import TitleCover from "../../components/site/titleCover";
import Tab from "../../components/site/tab";

const TeamPage = () => {
  return (
    <div>
      <Breadcrumb page="Notices and FAQs" />
      <Tab />
    </div>
  );
};

export default TeamPage;
