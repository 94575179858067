import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ViewColdStore from "../../components/admin/viewColdStore";
import AssignProduct from "../../components/admin/assignProduct";
import Rooms from "../../components/rooms";
import Dreadcrumb from "../../components/dreadcrumb";
import ColdstoreForm from "../../components/forms/coldstoreForm";
import EditColdStore from "../../components/admin/editColdStore";
import handleApiRequest from "../../libs/apiHandler";
const Coldstore = () => {
  const [coldstore, setColdstore] = useState([""]);
  const [storeForm, setStoreForm] = useState(false);
  const [update, setUpdate] = useState(false);
  const [assign, setAssign] = useState(false);
  const [view, setView] = useState(false);
  const [filteredColdstore, setFilteredColdstore] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [edit, setEdit] = useState(false);
  const [viewData, setViewData] = useState("");
  const [loading, setLoading] = useState(false);
  const [addRoom, setAddRoom] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchData();
  }, [update, storeForm, edit]);

  const fetchData = async () => {
    try {
      const targetPath = `coldstore/get-user-coldstore`;
      const result = await handleApiRequest("GET", targetPath, null, true);
      setColdstore(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const toggleForm = () => {
    setStoreForm(!storeForm);
  };

  const closeEditColdstore = () => {
    setEdit(false);
  };
  const deleteStore = async (storeId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const token = Cookies.get("user-token")
          ? Cookies.get("user-token")
          : null;
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_API_URL}/api/coldstore/delete/${storeId}`,
            {
              headers: {
                "user-token": token,
              },
            }
          );
          if (response.data.message) {
            Swal.fire("Deleted!", "coldstore has been deleted.", "success");
            setUpdate(!update);
          }
        }
      }
    });
  };

  const closeView = () => {
    setView(false);
  };

  const closeAssign = () => {
    setAssign(false);
  };

  const closeRoomForm = () => {
    setAddRoom(false);
  };
  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setFilteredColdstore(coldstore.slice(startIndex, endIndex));
  }, [currentPage, itemsPerPage, coldstore]);
  const filterColdstore = () => {
    if (searchTerm.trim() === "") {
      setFilteredColdstore(coldstore);
      return;
    }

    const filtered = coldstore.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredColdstore(filtered);
  };
  useEffect(() => {
    filterColdstore();
  }, [currentPage, itemsPerPage, searchTerm, coldstore]);

  return (
    <div className="h-screen overflow-scroll relative capitalize">
      {view && <ViewColdStore item={viewData} closeView={closeView} />}
      {addRoom && <Rooms coldstore={viewData} closeRoomForm={closeRoomForm} />}
      {assign && <AssignProduct closeAssign={closeAssign} item={viewData} />}
      {edit && (
        <EditColdStore
          item={viewData}
          closeEditColdstore={closeEditColdstore}
        />
      )}
      <Dreadcrumb page="coldstore" />
      <div className="p-4 flex justify-end">
        <button
          onClick={toggleForm}
          className="bg-green-600 text-gray-100 px-2 py-1 rounded"
        >
          {storeForm ? "close" : "Add Coldstore"}
        </button>
      </div>

      {storeForm && (
        <>
          <ColdstoreForm toggleForm={toggleForm} />
        </>
      )}

      {coldstore.length == 0 ? (
        <>
          {!storeForm && (
            <p className="text-red-500 tracking-wider px-4 mt-4">
              You don't have any coldstore yet.
              <span
                className="bg-green-600 px-2 py-1 rounded text-gray-100 text-sm cursor-pointer mx-4"
                onClick={() => setStoreForm(true)}
              >
                click here to add
              </span>
              a new one.
            </p>
          )}
        </>
      ) : (
        <div>
             <input
              type="text"
              placeholder="Search by coldstore name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="px-3 py-2 border rounded m-4"
            />
          <table className="min-w-full border-collapse block md:table">
         
            <thead className="block md:table-header-group ">
              <tr className="border border-gray-500 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto md:relative">
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  S.N.
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Coldstore Name
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  User Name
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Province
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  District
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Municipality
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredColdstore &&
                filteredColdstore.map((item, i) => (
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex items-center">
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {i + 1}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex items-center">
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {item.name}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item?.user?.fullName}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.status}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.provinceId?.name}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.districtId?.name}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item.municipalityId?.name}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm flex flex-wrap gap-2">
                      <span className="relative inline-block px-3 py-2 font-semibold text-white mx-1 leading-tight">
                        <span
                          aria-hidden
                          className="absolute inset-0 bg-green-700 hover:bg-green-900 rounded"
                        ></span>
                        <button
                          onClick={() => {
                            setView(true);
                            setViewData(item);
                          }}
                        >
                          <span className="relative">View More</span>
                        </button>
                      </span>
                      <span className="relative inline-block px-3 py-2 font-semibold text-white mx-1 leading-tight">
                        <span
                          aria-hidden
                          className="absolute inset-0 bg-blue-600 hover:bg-blue-900 rounded"
                        ></span>
                        <button
                          onClick={() => {
                            setEdit(true);
                            setViewData(item);
                          }}
                        >
                          <span className="relative"> Edit</span>
                        </button>
                      </span>
                      <span className="relative inline-block px-3 py-2 font-semibold text-white mx-1 leading-tight">
                        <span
                          aria-hidden
                          className="absolute inset-0 bg-red-600 hover:bg-red-800 rounded"
                        ></span>
                        <button onClick={() => deleteStore(item._id)}>
                          <span className="relative"> Delete</span>
                        </button>
                      </span>
                      <span className="relative inline-block px-3 py-2 font-semibold text-white mx-1 leading-tight">
                        <span
                          aria-hidden
                          className="absolute inset-0 bg-gray-600 hover:bg-gray-900 rounded"
                        ></span>
                        <button
                          onClick={() => {
                            setAddRoom(true);
                            setViewData(item);
                          }}
                        >
                          <span className="relative"> Assign Rooms</span>
                        </button>
                      </span>
                      <span className="relative inline-block px-3 py-2 font-semibold text-white mx-1 leading-tight">
                        <span
                          aria-hidden
                          className="absolute inset-0 bg-green-700 hover:bg-green-900 rounded"
                        ></span>
                        <button
                          onClick={() => {
                            setAssign(true);
                            setViewData(item);
                          }}
                        >
                          <span className="relative"> Assign Product</span>
                        </button>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="flex items-center mt-4 p-4">
            <label htmlFor="itemsPerPage" className="mr-2">
              Items per page:
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
              className="px-2 py-1 border rounded"
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
              {Math.min(currentPage * itemsPerPage, coldstore.length)} of{" "}
              {coldstore.length} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0 gap-2">
              <button
                className="text-sm text-green-50 transition duration-150 hover:bg-green-900 bg-green-600 font-semibold py-2 px-4 rounded-l"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Prev
              </button>
              {Array.from(
                { length: Math.ceil(coldstore.length / itemsPerPage) },
                (_, index) => (
                  <button
                    key={index}
                    className={`text-sm transition duration-150 py-2 px-4 ${
                      currentPage === index + 1
                        ? "text-green-900 font-semibold bg-green-300"
                        : "text-green-50 hover:bg-green-900 bg-green-600"
                    }`}
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </button>
                )
              )}
              <button
                className="text-sm text-green-50 transition duration-150 hover:bg-green-900 bg-green-600 font-semibold py-2 px-4 rounded-r"
                disabled={
                  currentPage === Math.ceil(coldstore.length / itemsPerPage)
                }
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Coldstore;
