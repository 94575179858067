import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import userAuth from "../../libs/userAuth";
import Swal from "sweetalert2";
import Coldstore from "./coldstore";
import NewSensorData from "../../components/user/newSensorData";
import AssignedProduct from "../../components/user/assignProduct";
import Dreadcrumb from "../../components/dreadcrumb";
const Dashboard = () => {
  const [coldstore, setColdstore] = useState("");
  const [update, setUpdate] = useState(false);
  const [user, setUser] = useState("");
  const [view, setView] = useState(false);
  const [viewData, setViewData] = useState("");
  const currentHour = new Date().getHours();
  let greeting;

  if (currentHour < 12) {
    greeting = "Good morning";
  } else if (currentHour < 18) {
    greeting = "Good afternoon";
  } else {
    greeting = "Good evening";
  }
  useEffect(() => {
    userAuth();
  }, []);
  useEffect(() => {
    const fetchColdstore = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/coldstore/user`,
        {
          headers: {
            "user-token": Cookies.get("user-token"),
          },
        }
      );
      setColdstore(response.data);
    };
    fetchColdstore();
  }, [update]);
  useEffect(() => {
    const user = JSON.parse(Cookies.get("user-detail"));
    setUser(user);
  }, []);
  const handleDelete = (storeId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const token = Cookies.get("user-token")
          ? Cookies.get("user-token")
          : null;
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_API_URL}/api/coldstore/delete/${storeId}`,
            {
              headers: {
                "user-token": token,
              },
            }
          );
          if (response.data.message) {
            Swal.fire("Deleted!", "Your store has been deleted.", "success");
            setUpdate(!update);
          }
        }
      }
    });
  };
  const closeView = () => {
    setView(false);
  };
  return (
    <div className="w-full dark:bg-slate-100 h-[100%] overflow-y-scroll relative">
      <Dreadcrumb page="dashboard" />
      <div>
        <div className="w-full bg-gray-50 flex justify-center items-center py-4">
          <div className="h-56 w-72 absolute flex justify-center items-center">
            <img
              className="object-cover h-20 w-20 rounded"
              src="https://img.freepik.com/free-icon/user_318-159711.jpg"
              alt=""
            />
          </div>

          <div
            className="
          h-56
          mx-4
          w-5/6
          bg-blue-400
          rounded-3xl
          shadow-md
          sm:w-80 sm:mx-0
        "
          >
            <div className="h-1/2 w-full flex justify-between items-baseline px-3 py-5">
              <h1 className="text-white">Profile</h1>
            </div>
            <div
              className="
            bg-white
            h-1/2
            w-full
            rounded-3xl
            flex flex-col
            justify-around
            items-center
          "
            >
              <div className="w-full h-1/2 flex justify-between items-center px-3 pt-2"></div>
              <div className="w-full h-1/2 flex flex-col justify-center items-center">
                <h1 className="text-gray-700 font-bold">
                  {greeting}, {user.fullName}
                </h1>
                <h1 className="text-gray-500 text-sm">{user.email}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewSensorData />
      <Coldstore />
    </div>
  );
};

export default Dashboard;
