import React, { useState } from "react";
import TitleCover from "./titleCover";
import PrimaryCard from "./cards/primaryCard";
import Faq from "./faq";
const NoticeItem = ({ title, publication, img }) => {
  return (
    <li className="border-l-2 border-green-600">
      <div className="md:flex flex-start lg:w-[100%] w-[95%]">
        <div className="bg-green-600 w-6 h-6 flex items-center justify-center rounded-full -ml-3.5">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            className="text-white w-3 h-3"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            {/* SVG path data */}
          </svg>
        </div>
        <div className="block p-6 rounded-lg shadow-lg bg-gray-100 w-[100%] lg:px-4 px-2 lg:mx-5 mx-2 mb-10">
          <div className="flex flex-col gap-2 justify-between mb-4">
            <p className="font-medium text-gray-600 hover:text-gray-700 focus:text-gray-800 duration-300 transition ease-in-out text-sm">
              {title}
            </p>
            <p className="font-medium text-green-600 hover:text-green-700 focus:text-green-800 duration-300 transition ease-in-out text-sm">
              {publication}
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};

const Tab = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleCurrentTab = (n) => {
    setCurrentTab(n);
  };

  const notices = [
    {
      title:
        "Notice to producers and importers regarding organic and bio-fertilizers online application and management software",
      publication: "Published Date: 2023-03-22",
      img: "http://doanepal.gov.np/ne/assets/img/nepal_logo.png",
    },
    {
      title:
        "Notice on EXPRESSION OF INTEREST (EOI) :Estimation of Production Cost and Benefit of Major Crops (DoA/CS/QCBS- 01/2079/80)",
      publication: "Published Date: 2023-03-22",
      img: "http://doanepal.gov.np/ne/assets/img/nepal_logo.png",
    },
    {
      title:
        "List of seniority of in-service technical training or similar for second class officer",
      publication: "Published Date: 2023-03-22",
      img: "http://doanepal.gov.np/ne/assets/img/nepal_logo.png",
    },
    {
      title:
        "Notice on extension of deadline for papers submission on Conference of Agro-ecology for Sustainable and Resilient Food System",
      publication: "Published Date: 2023-03-22",
      img: "http://doanepal.gov.np/ne/assets/img/nepal_logo.png",
    },
    {
      title:
        "Notice to producers and importers regarding organic and bio-fertilizers online application and management software",
      publication: "Published Date: 2023-03-22",
      img: "http://doanepal.gov.np/ne/assets/img/nepal_logo.png",
    },
  ];

  return (
    <div className="parallax">
      <TitleCover title="Notices and FAQs" />
      <div className="container mx-auto my-6 ">
        <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-2 gap-0 px-4">
          <ol>
            {notices.map((notice, index) => (
              <NoticeItem
                key={index}
                title={notice.title}
                publication={notice.publication}
                img={notice.img}
              />
            ))}
          </ol>
          <div className="">
            <Faq />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
