import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import handleApiRequest from "../../libs/apiHandler";
import Loader from "../loader";
import EditAssignProduct from "./editAssignProduct";

const ViewProduct = ({ coldstore }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editAssignedProduct, setEditAssignedProduct] = useState(false);
  const [item, setItem] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const targetPath = `coldstore/get-assigned-product/${coldstore?._id}`;
      const result = await handleApiRequest("GET", targetPath, null, true);
      setProducts(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const calculateStorageDays = (dateOfStorage) => {
    const today = new Date();
    const storageDate = new Date(dateOfStorage);
    const timeDifference = today.getTime() - storageDate.getTime();
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return days;
  };
  const handleDelete = async (targetId) => {
    setLoading(true);
    try {
      const targetPath = `coldstore/delete-assign-product/${targetId}`;
      const result = await handleApiRequest("DELETE", targetPath, null, true);
      if (result.message) {
        Swal.fire("Success", result.message, "success");
        setLoading(false);
        fetchData();
      } else {
        Swal.fire("Error", "An error occurred while submitting data.", "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const closeAssignProduct = () => {
    setEditAssignedProduct(false);
  };

  return (
    <div className="mb-8">
      {loading && <Loader />}
      {editAssignedProduct && (
        <EditAssignProduct
          closeAssignProduct={closeAssignProduct}
          item={item}
        />
      )}
      <h1 className="lg:text-2xl text-lg font-semibold mb-4 text-green-600 mt-4">
        list of products assigned to rooms in this coldstore.
      </h1>
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100 text-left text-gray-600">
            <th className="border p-2">#</th>
            <th className="border p-2">Room</th>
            <th className="border p-2">Storage Type</th>
            <th className="border p-2">Number of Containers</th>
            <th className="border p-2">Date of Storage</th>
            <th className="border p-2">Storage Days</th>
            <th className="border p-2">Product</th>
            <th className="border p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {products &&
            products.map((product, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-gray-50" : ""}>
                <td className="border p-2">{index + 1}</td>
                <td className="border p-2">{product.roomId?.roomName}</td>
                <td className="border p-2">{product.storageType}</td>
                <td className="border p-2">{product.numberOfContainers}</td>
                <td className="border p-2">
                  {new Date(product.dateOfStorage).toLocaleDateString()}
                </td>
                <td className="border p-2">
                  {calculateStorageDays(product.dateOfStorage)} Days
                </td>
                <td className="border p-2">{product.productId?.name}</td>
                <td className="border p-2">
                  <button
                    onClick={() => {
                      setEditAssignedProduct(true);
                      setItem(product);
                    }}
                    className="text-blue-600 hover:underline mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(product?._id)}
                    className="text-red-600 hover:underline"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewProduct;
