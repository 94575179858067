import React from "react";
import { Link } from "react-router-dom";
import htmr from "htmr";
const ServiceCard = ({ i, service }) => {
  const isEven = i % 2 === 0;

  return (
    <div className="flex lg:flex-row flex-col-reverse block items-center justify-center lg:gap-4 gap-3 p-3 mt-4">
      <div className="w-full mx-auto rounded shadow overflow-hidden">
        <div className="mx-auto">
          <div className="p-2">
            <p className="font-bold text-green-600  mb-1 py-2 lg:text-3xl text-xl">
              {service.title}
            </p>
            <p className="text-[#7C7C80] lg:text-xl text-sm lg:mt-4 mt-0">
              {htmr(service.description)}
            </p>
            <a className="block mt-8 w-[40%] px-2 py-2 font-medium tracking-wide text-center capitalize transition-colors duration-300 transform bg-green-600 text-white rounded hover:bg-green-800 focus:outline-none focus:ring focus:ring-teal-300 focus:ring-opacity-80">
              <Link to={service.slug}>View More</Link>
            </a>
          </div>
        </div>
      </div>
      <img
        src={service.img}
        alt=""
        className={`lg:w-[50rem] w-[100%] ${!isEven ? "lg:order-first order-last" : ""} rounded object-cover h-[30rem]`}
      />
    </div>
  );
};

export default ServiceCard;
