import React, { useState, useEffect } from "react";

const TitleCover = ({ title }) => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const parallaxOffset = scrollY * 0.5; // Adjust the parallax speed here

  return (
    <div className="relative overflow-hidden h-[8rem]  bg-white">
      {/* <img
        src="/assets/s1.jpg"
        alt="breadcrumb"
        className="absolute w-full top-0 z-[1] h-[8rem] object-cover"
        style={{ transform: `translateY(${parallaxOffset}px)` }}
      /> */}
      <div className="absolute top-0 bg-[#00800082] w-full z-[95]"></div>
      <div className="flex justify-center items-center absolute top-0 w-full z-[1]">
        <p className="text-green-600 mt-10 lg:text-4xl text-3xl font-semibold tracking-wider capitalize">
          {title}
        </p>
      </div>
    </div>
  );
};

export default TitleCover;
