import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Loader from "../../components/loader";
import NepaliDatepicker from "nepali-datepicker-and-dateinput";
const ColdstoreForm = ({ toggleForm }) => {
  const [userList, setUserList] = useState([""]);
  const [name, setName] = useState("");
  const [user, setUser] = useState("");
  const [ward, setWard] = useState("");
  const [tole, setTole] = useState("");
  const [loading, setLoading] = useState("");
  const [established, setEstablished] = useState("");
  const [status, setStatus] = useState("");
  const [provinceId, setProvinceId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [municipalityId, setMunicipalityId] = useState("");
  const [provinces, setProvinces] = useState("");
  const [districts, setDistricts] = useState("");
  const [municipalities, setMunicipalities] = useState("");
  const [filteredDistrict, setFilteredDistrict] = useState([]);
  const [filteredMunicipality, setFilteredMunicipality] = useState([]);
  const userDetails = JSON.parse(Cookies.get("user-detail"));

  const handleDateChange = (name, dateInMilli, bsDate, adDate) => {
    setEstablished(bsDate);
  };

  const filterDistrict = (provinceId) => {
    const data = districts.filter(
      (item, i) => item.provinceId?._id === provinceId
    );
    setFilteredDistrict(data);
  };

  const filterMuniciplity = (districtId) => {
    const data = municipalities?.filter(
      (item, i) => item.districtId?._id === districtId
    );
    setFilteredMunicipality(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/coldstore/add`,
      {
        name,
        user,
        established,
        provinceId,
        districtId,
        municipalityId,
        ward,
        tole,
        status,
      },
      {
        headers: {
          "user-token": Cookies.get("user-token"),
        },
      }
    );

    if (response) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: response.data.message,
      });
      setLoading(false);
      toggleForm();
    }
  };
  useEffect(() => {
    const fetchMunicipalities = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/location/municipality/get-all`
      );
      setMunicipalities(response.data);
    };
    fetchMunicipalities();
  }, []);

  useEffect(() => {
    const fetchDistricts = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/location/district/get-all`
      );
      setDistricts(response.data);
    };
    fetchDistricts();
  }, []);
  useEffect(() => {
    try {
      const fetchUsers = async () => {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/api/user`
        );
        setUserList(response.data);
      };
      fetchUsers();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const fetchProvinces = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/location/province/get-all`
      );
      setProvinces(response.data);
    };
    fetchProvinces();
  }, []);
  return (
    <div>
      {loading && <Loader />}
      <div className="flex items-center justify-center">
        <div className="mx-auto w-full max-w-[900px] shadow p-4">
          <p className="py-2 lg:text-xl text-lg">Add a New Coldstore.</p>
          <form action="https://formbold.com/s/FORM_ID" method="POST">
            <div className="grid lg:grid-cols-2 gap-8">
              <div className="mb-5 lg:col-span-1  col-span-2">
                <label
                  for="coldstore name"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  coldstore name
                </label>
                <input
                  type="text"
                  name="coldstore_name"
                  id="name"
                  placeholder="Enter store name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>
              <div className="mb-5  lg:col-span-1  col-span-2">
                <label
                  for="coldstore name"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  assign to user
                </label>
                {userDetails.role === "admin" ? (
                  <select
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    name="user"
                    id="user"
                  >
                    <option>select user</option>
                    {userList.map((item, i) => (
                      <option value={item._id} key={i}>
                        {item.fullName}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    name="user"
                    id="user"
                  >
                    <option>select user</option>

                    <option value={userDetails._id}>
                      {userDetails.fullName}
                    </option>
                  </select>
                )}
              </div>
              <div className="mb-5 col-span-2">
                <label
                  for="coldstore location"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  coldstore location
                </label>
                <div className="grid lg:grid-cols-3 grid-col-1 gap-4">
                  <select
                    onChange={(e) => {
                      setProvinceId(e.target.value);
                      filterDistrict(e.target.value);
                    }}
                    value={provinceId}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  >
                    <option value="">select province</option>
                    {provinces &&
                      provinces.map((item, i) => (
                        <option key={i} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                  <select
                    onChange={(e) => {
                      setDistrictId(e.target.value);
                      filterMuniciplity(e.target.value);
                    }}
                    value={districtId}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  >
                    <option value="">select district</option>
                    {districts &&
                      filteredDistrict.map((item, i) => (
                        <option key={i} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                  <select
                    onChange={(e) => {
                      setMunicipalityId(e.target.value);
                    }}
                    value={municipalityId}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  >
                    <option value="">select municipality</option>
                    {municipalities &&
                      filteredMunicipality.map((item, i) => (
                        <option key={i} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                  <input
                    type="text"
                    name="wardno"
                    id="wardno"
                    placeholder="Enter ward number"
                    value={ward}
                    onChange={(e) => setWard(e.target.value)}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                  <input
                    type="text"
                    name="tole"
                    id="tole"
                    placeholder="Enter tole"
                    value={tole}
                    onChange={(e) => setTole(e.target.value)}
                    className="w-full rounded-md border border-[rgb(224,224,224)] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
              <div className="mb-5  lg:col-span-1  col-span-2">
                <label
                  for="coldstore name"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Year of establishment
                </label>
                <NepaliDatepicker
                  id="nepali-date"
                  onChange={handleDateChange}
                  label="नेपाली मिति"
                  defaultDate="2077-01-01"
                  defaultFormat
                />
              </div>

              <div>
                <label
                  for="coldstore status"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  coldstore status
                </label>
                {userDetails.role == "admin" && (
                  <div className="mb-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        value="approved"
                        checked={status === "approved"}
                        onChange={(e) => setStatus(e.target.value)}
                        className="form-checkbox h-5 w-5 text-indigo-600"
                      />
                      <span className="ml-2">Approved</span>
                    </label>
                  </div>
                )}
                {userDetails.role === "admin" ||
                  (userDetails.role === "user" && (
                    <div className="mb-4">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          value="pending"
                          checked={status === "pending"}
                          onChange={(e) => setStatus(e.target.value)}
                          className="form-checkbox h-5 w-5 text-indigo-600"
                        />
                        <span className="ml-2">Pending</span>
                      </label>
                    </div>
                  ))}
                {userDetails.role === "admin" && (
                  <div className="mb-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        value="cancelled"
                        checked={status === "cancelled"}
                        onChange={(e) => setStatus(e.target.value)}
                        className="form-checkbox h-5 w-5 text-indigo-600"
                      />
                      <span className="ml-2">cancelled</span>
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div>
              <button
                onClick={handleSubmit}
                className="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-base font-semibold text-white outline-none"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ColdstoreForm;
