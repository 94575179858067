import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import handleApiRequest from "../../libs/apiHandler";

const AssignProduct = ({ closeAssign, item }) => {
  const [roomId, setRoomId] = useState("");
  const [storageType, setStorageType] = useState("");
  const [numberOfContainers, setNumberOfContainers] = useState("");
  const [dateOfStorage, setDateOfStorage] = useState("");
  const [productId, setProductId] = useState("");
  const [products, setProducts] = useState([]);
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const targetPath = `room/get-user-room/${item?._id}`;
        const result = await handleApiRequest("GET", targetPath, null, true);
        setRooms(result);
        console.log(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const targetPath = `product/get`;
        const result = await handleApiRequest("GET", targetPath, null, false);
        setProducts(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchProduct();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      roomId,
      storageType,
      numberOfContainers,
      dateOfStorage,
      coldstoreId: item._id,
      productId,
    };
    try {
      const targetPath = `coldstore/assign_product`;
      const result = await handleApiRequest("POST", targetPath, data, true);
      if (result.message) {
        Swal.fire({
          title: "Success",
          text: result.message,
          icon: "success",
        });
        closeAssign();
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Failed to post data. Please try again.",
        icon: "error",
      });
    }
  };

  return (
    <div className="w-full bg-[#ffffff63] fixed z-20 inset-0">
      <div className="lg:w-[50%] w-[100%] mt-4 top-0 bg-gray-100 mx-auto p-6 shadow-md rounded">
        <button
          onClick={closeAssign}
          className="bg-red-500 rounded px-2 py-1 text-white float-right"
        >
          <i className="fas fa-times"></i>
        </button>
        <p className="capitalize text-xl font-semibold text-green-600 py-2">
          Assign Product
        </p>
        <p className="text-sm text-gray-600 tracking-wider">
          Please assign th e product that you wish to store in your cold store
          here.
        </p>
        <form
          className="max-w-xl mx-auto shadow p-8 rounded text-gray-600 grid grid-cols-2 gap-2"
          onSubmit={handleSubmit}
        >
          <div className="mb-4">
            <label htmlFor="product" className="block font-medium mb-1">
              Select Product:
            </label>
            <select
              value={productId}
              onChange={(e) => setProductId(e.target.value)}
              id="product"
              className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
              required
            >
              <option value="">Select product</option>
              {products.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="product" className="block font-medium mb-1">
              Select Rooms:
            </label>
            <select
              value={roomId}
              onChange={(e) => setRoomId(e.target.value)}
              id="room"
              className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none"
              required
            >
              <option value="">Select room</option>
              {rooms.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.roomName}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="storageType" className="block font-medium mb-1">
              Storage Type:
            </label>
            <select
              id="storageType"
              className="w-full border border-gray-300 rounded px-3 py-2"
              value={storageType}
              onChange={(e) => setStorageType(e.target.value)}
              required
            >
              <option value="">Select Storage Type</option>
              <option value="crate">Crate</option>
              <option value="sacks">Sacks</option>
            </select>
          </div>

          <div className="mb-4">
            <label
              htmlFor="numberOfContainers"
              className="block font-medium mb-1"
            >
              Number of Containers:
            </label>
            <input
              type="number"
              id="numberOfContainers"
              className="w-full border border-gray-300 rounded px-3 py-2"
              value={numberOfContainers}
              onChange={(e) => setNumberOfContainers(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="dateOfStorage" className="block font-medium mb-1">
              Date of Storage:
            </label>
            <input
              type="date"
              id="dateOfStorage"
              className="w-full border border-gray-300 rounded px-3 py-2"
              value={dateOfStorage}
              onChange={(e) => setDateOfStorage(e.target.value)}
              required
            />
          </div>

          <button
            type="submit"
            className="bg-green-500 text-white rounded px-4 py-2 hover:bg-green-600 col-span-2"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AssignProduct;
