import React, { useState } from "react";
import { Link } from "react-router-dom";
import Province from "../../components/admin/province";
import District from "../../components/admin/district";
import Municipality from "../../components/admin/municipality";

const Location = () => {
  const [submitState, setSubmitState] = useState(false);
  const toggleState = () => {
    setSubmitState(!submitState);
  };
  return (
    <div className="text-gray-600">
      <nav
        className="flex px-5 py-3 text-gray-700 bg-gray-50 dark:bg-[#1E293B] "
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
              <svg
                className="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              Home
            </Link>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <Link className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                Location
              </Link>
            </div>
          </li>
        </ol>
      </nav>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
        <div title="province" className="p-4 shadow">
          <Province toggleState={toggleState} />
        </div>
        <div title="district">
          <District toggleState={toggleState} submitState={submitState} />
        </div>
        <div title="municipality">
          <Municipality toggleState={toggleState} submitState={submitState} />
        </div>
      </div>
    </div>
  );
};

export default Location;
