import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ page, innerPage }) => {
  return (
    <div className="container mx-auto">
      <div className="px-4">
        <ul className="breadcrumb">
          <li className="breadcrumb__item breadcrumb__item-firstChild">
            <span className="breadcrumb__inner">
              <span className="breadcrumb__title capitalize">
                <Link to="/">Home</Link>
              </span>
            </span>
          </li>
          <li className="breadcrumb__item">
            <span className="breadcrumb__inner">
              <span className="breadcrumb__title capitalize">{page}</span>
            </span>
          </li>
          {innerPage && (
            <li className="breadcrumb__item">
              <span className="breadcrumb__inner">
                <span className="breadcrumb__title capitalize">{innerPage}</span>
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumb;
