import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
const Slider = () => {
  return (
    <div>
      <Swiper navigation={true} modules={[Navigation]} className="mySlider">
        <SwiperSlide>
          <div className="relative ">
            <img src="/assets/s2.jpg" alt="img" />
            <div className="absolute lg:top-1/3 lg:left-1/2 left-[13rem] w-full top-[10rem] transform -translate-x-1/2 z-[999] inset-0 lg:px-1 px-5">
              <h1 className="lg:text-5xl text-4xl py-4 font-serif font-semibold text-gray-100">
                Your Trusted Cold Storage Solution
              </h1>
              <p className="font-serif font-semibold text-gray-100 py-2 lg:text-3xl text-2xl">
                Preserving Freshness, Ensuring Quality
              </p>
              <div className="flex flex-row gap-6 justify-center items-center mt-4">
                <button className="bg-green-600 rounded px-3 py-2 text-gray-100 border-2 hover:bg-transparent hover:text-gray-100 hover:border-gray-100">
                  Explore More
                </button>
                <button className="bg-green-600 rounded px-3 py-2 text-gray-100 border-2 hover:bg-transparent hover:text-gray-100 hover:border-gray-100">
                  <Link to="/contact">Contact Now</Link>
                </button>
              </div>
            </div>

            <div className="w-[100vw] h-full bg-[#0000004d] absolute top-0 z-[900]"></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <img src="/assets/s1.jpg" alt="img" />
            <div className="absolute lg:top-1/3 lg:left-1/2 left-[13rem] w-full top-[10rem] transform -translate-x-1/2 z-[999] inset-0 lg:px-1 px-5">
              <p className="lg:text-5xl text-4xl py-4 font-serif font-semibold text-gray-100">
                Keeping Goods Fresh, Day and Night.
              </p>
              <p className="font-serif font-semibold text-gray-100 py-2 lg:text-3xl text-2xl">
                Our Cold Storage Solutions for Your Products.
              </p>
              <div className="flex flex-row gap-6 justify-center items-center mt-4">
                <button className="bg-green-600 rounded px-3 py-2 text-gray-100 border-2 hover:bg-transparent hover:text-gray-100 hover:border-gray-100">
                  Explore More
                </button>
                <button className="bg-green-600 rounded px-3 py-2 text-gray-100 border-2 hover:bg-transparent hover:text-gray-100 hover:border-gray-100">
                  <Link to="/contact">Contact Now</Link>
                </button>
              </div>
            </div>
            <div className="w-[100vw] h-full bg-[#0000004d] absolute top-0 z-[900]"></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative">
            <img src="/image/i9.jpg" alt="img" />
            <div className="absolute lg:top-1/3 lg:left-1/2 left-[13rem] w-full top-[10rem] transform -translate-x-1/2 z-[999] inset-0 lg:px-1 px-5">
              <p className="lg:text-5xl text-4xl py-4 font-serif font-semibold text-gray-100">
                Precision Temperature Control
              </p>
              <p className="font-serif font-semibold text-gray-100 py-2 lg:text-3xl text-2xl">
                Expertly Maintained Conditions for Optimal Freshness
              </p>
              <div className="flex flex-row gap-6 justify-center items-center mt-4">
                <button className="bg-green-600 rounded px-3 py-2 text-gray-100 border-2 hover:bg-transparent hover:text-gray-100 hover:border-gray-100">
                  Explore More
                </button>
                <button className="bg-green-600 rounded px-3 py-2  text-gray-100 border-2 hover:bg-transparent hover:text-gray-100 hover:border-gray-100">
                  <Link to="/contact">Contact Now</Link>
                </button>
              </div>
            </div>
            <div className="w-[100vw] h-full bg-[#0000004d] absolute top-0 z-[900]"></div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Slider;
