import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import * as XLSX from "xlsx";
const NewSensorData = ({ userId }) => {
  const [sensorData, setSensorData] = useState(null);
  const [showAllData, setShowAllData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  useEffect(() => {
    const fetchSensorData = async () => {
      try {
        const token = Cookies.get("user-token");
        if (!token) {
          console.error("Token not found in cookies.");
          window.location.replace("/login");
        }

        const apiUrl = userId
          ? `${process.env.REACT_APP_BASE_API_URL}/api/root/get-user-data-by-admin/${userId}`
          : `${process.env.REACT_APP_BASE_API_URL}/api/root/get-user-data`;

        const response = await axios.get(apiUrl, {
          headers: {
            "user-token": token,
          },
        });
        console.log(response);
        setSensorData(response.data);
      } catch (error) {
        console.error("Error fetching sensor data:", error);
      }
    };

    fetchSensorData();
    const interval = setInterval(fetchSensorData, 10000);
    return () => clearInterval(interval);
  }, [userId]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date().getTime();
    const diff = now - timestamp;

    if (diff < 1000) {
      return "Just now";
    } else if (diff < 60000) {
      return Math.floor(diff / 1000) + " sec ago";
    } else if (diff < 3600000) {
      return Math.floor(diff / 60000) + " min ago";
    } else if (diff < 86400000) {
      return Math.floor(diff / 3600000) + " hours ago";
    } else if (diff < 172800000) {
      return Math.floor(diff / 86400000) + " day ago";
    } else {
      const date = new Date(timestamp);
      return date.toDateString();
    }
  };
  const generateExcelFile = () => {
    if (!sensorData) {
      return;
    }

    const uniqueKeys = Array.from(
      new Set(sensorData.flatMap((data) => Object.keys(data.sensorData)))
    );

    const headers = [
      "createdAt",
      "deviceId",
      "email",
      ...uniqueKeys,
      "timestamp",
      "updatedAt",
    ];
    const rows = sensorData.map((data) => {
      const rowData = headers.map((header) => {
        if (header in data) {
          return data[header];
        } else if (header in data.sensorData) {
          return data.sensorData[header];
        } else {
          return "";
        }
      });
      return rowData;
    });

    const data = [headers, ...rows];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SensorData");

    const excelBlob = new Blob(
      [XLSX.write(wb, { bookType: "xlsx", type: "array" })],
      {
        type: "application/octet-stream",
      }
    );
    const excelFilePath = "sensor_data.xlsx";
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(excelBlob);
    downloadLink.download = excelFilePath;
    downloadLink.click();
  };

  if (!sensorData || sensorData.length === 0) {
    return <p className="text-center mt-4">Loading sensor data...</p>;
  }
  const lastItem = sensorData[sensorData.length - 1];
  const toggleShowAllData = () => {
    setShowAllData(!showAllData);
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sensorData.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(sensorData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div className="container mx-auto px-4 py-6">
      <div className="bg-white shadow-md rounded-lg p-6 ">
        <div className="flex flex-row gap-4 justify-between">
          <h3 className="lg:text-2xl text-xl text-gray-600 font-semibold mb-6">
            Sensor Data
          </h3>
          <h3 className="lg:text-xl text-sm text-gray-600 font-semibold mb-6">
            <p>
              <small>Device ID:</small> {lastItem.deviceId}
            </p>{" "}
            {getTimeAgo(lastItem.updatedAt)}
            <br />
            <small>{formatTimestamp(lastItem.timestamp)}</small>
          </h3>
        </div>
        <ul className="list-none pl-6 grid lg:grid-cols-5 grid-cols-1 gap-4">
          {Object.entries(lastItem.sensorData).map(([key, value]) => (
            <li
              key={key}
              className="bg-green-600 flex justify-between items-center p-3 rounded shadow-md"
            >
              <span className="lg:text-2xl text-lg capitalize text-gray-100">
                {key}:
              </span>{" "}
              <span className="lg:text-xl text-sm font-semibold bg-white rounded-full px-3 py-2 shadow-lg">
                {value}
              </span>
            </li>
          ))}
        </ul>
      </div>
      {/* <div className="bg-white shadow-md rounded-lg p-6 mt-4">
        <h2 className="text-xl font-semibold mb-2">Data Details</h2>
        <p>
          <strong>Email:</strong> {lastItem.email}
        </p>

        <p>
          <strong>Timestamp:</strong> {formatTimestamp(lastItem.timestamp)}
        </p>
        <p>
          <strong>Created At:</strong> {formatTimestamp(lastItem.createdAt)}
        </p>
        <p>
          <strong>Updated At:</strong> {formatTimestamp(lastItem.updatedAt)}
        </p>
      </div> */}
      <div>
        <div className="mt-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
            onClick={toggleShowAllData}
          >
            {showAllData ? "Hide All Data" : "Show All Data"}
          </button>
        </div>
        {showAllData && (
          <div className="bg-white shadow-md rounded-lg p-6 mt-4">
            <div className="flex flex-row justify-between p-3">
              <h2 className="lg:text-xl text-lg font-semibold mb-2">
                All Sensor Data
              </h2>
              <button
                className="bg-green-500 text-white lg:px-4 px-2 lg:py-2 py-1 rounded-md lg:text-sm text-xs"
                onClick={generateExcelFile}
              >
                Download Excel
              </button>
            </div>
            <hr className="h-3" />
            <ul className="list-none flex flex-col gap-4 pl-6">
              {currentItems &&
                currentItems.reverse().map((data, index) => (
                  <li key={index}>
                    <div className="bg-white shadow-md rounded-lg p-6 ">
                      <div className="flex flex-row gap-4 justify-between">
                        <h3 className="lg:text-2xl text-xl text-gray-600 font-semibold mb-6">
                          Sensor Data
                        </h3>

                        <h3 className="lg:text-xl text-sm text-gray-600 font-semibold mb-6">
                          <p>
                            <small>Device ID:</small> {lastItem.deviceId}
                          </p>
                          {getTimeAgo(data.updatedAt)}
                          <br />
                          <small>{formatTimestamp(data.timestamp)}</small>
                        </h3>
                      </div>
                      <ul className="list-none grid lg:grid-cols-5 grid-cols-1 gap-4">
                        {Object.entries(data?.sensorData).map(
                          ([key, value]) => (
                            <li
                              key={key}
                              className="bg-green-600 flex justify-between items-center p-3 rounded shadow-md"
                            >
                              <span className="text-2xl capitalize text-gray-100">
                                {key}:
                              </span>{" "}
                              <span className="text-xl font-semibold bg-white rounded-full px-3 py-2 shadow-lg">
                                {value}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </li>
                ))}
            </ul>
            <div className="flex justify-between items-center mt-4">
              <div className="flex items-center">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Prev
                </button>
                {pageNumbers.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    className={`px-3 py-2 rounded-md mr-2 ${
                      pageNumber === currentPage
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-gray-800"
                    }`}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                ))}
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md ml-2"
                  disabled={currentPage === pageNumbers.length}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              </div>
              <div className="flex items-center">
                <label className="mr-2">Items per page:</label>
                <select
                  className="px-3 py-2 rounded-md bg-gray-200"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewSensorData;
