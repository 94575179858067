import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
const Province = ({toggleState}) => {
  const [province, setProvince] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [state, setState] = useState(false);
  useEffect(() => {
    const fetchProvinces = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/location/province/get-all`
      );
      setProvinces(response.data);
    };
    fetchProvinces();
  }, [state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/location/province/add`,
      {
        name: province,
      }
    );
    if (response.data.message) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: response.data.message,
      });
      setProvince("");
      setState(!state);
      toggleState()
    }
  };

  const deleteProvince = async (orovinceId) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_API_URL}/api/location/province/delete/${orovinceId}`
    );
    if (response.data.message) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: response.data.message,
      });
      setState(!state);
      toggleState()
    }
  };
  return (
    <div>
      <form action="">
        <div className="mb-5">
          <label
            for="province"
            className="mb-3 block text-base font-medium text-[#07074D]"
          >
            province
          </label>
          <input
            type="text"
            name="province"
            id="name"
            placeholder="Enter province name"
            value={province}
            required
            onChange={(e) => setProvince(e.target.value)}
            className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
          />
        </div>
        <div>
          <button
            onClick={handleSubmit}
            className="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-base font-semibold text-white outline-none float-right"
          >
            Submit
          </button>
        </div>
      </form>
      <div>
      <p className="text-sm text-gray-600 font-semibold py-2 px-3 font-serif">
          lists of provinces
        </p>
        <div className="flex flex-col gap-4 mt-4">
          {provinces &&
            provinces.map((item, i) => (
              <div key={i} className="flex justify-between gap-2">
                <p>{item.name}</p>
                <button onClick={() => deleteProvince(item._id)} className="bg-red-500 text-white px-2 py-1 rounded
                ">Delete</button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Province;
