import React from "react";
import TitleCover from "./titleCover";

const ColdstoreSection = ({ title, content, imageSrc, index }) => {
  const isEvenIndex = index % 2 === 0;

  return (
    <>
      <div className={`container flex ${isEvenIndex ? "lg:flex-row flex-col" : "lg:flex-row-reverse flex-col"}  px-6 py-4 mx-auto space-y-2`}>
        <div className={`flex items-center justify-center w-full h-96 md:w-1/2`}>
          <img
            className="object-cover w-full h-full max-w-2xl rounded-md"
            src={imageSrc}
            alt="section image"
          />
        </div>
        <div className="flex flex-col-reverse items-center w-full md:flex-row md:w-1/2">
          <div className="max-w-lg md:mx-12 md:order-2">
            <h1 className="lg:text-3xl text-xl font-semibold tracking-wide text-green-600 py-2">
              {title}
            </h1>
            <p className="lg:mt-4 mt-1 text-gray-600 lg:text-lg text-sm">{content}</p>
          </div>
        </div>
      </div>
      <hr className="h-1" />
    </>
  );
};


const sections = [
  {
    title: "Cold storage",
    content:
      "Cold storage keeps perishable items fresh by storing them at low temperatures for a longer time. It's important for industries like food and medicine to ensure products stay safe and last longer. These facilities use special cooling systems and follow rules to be energy efficient. Cold storage is a crucial part of the cold chain system and helps industries like biotechnology and agriculture.",
    imageSrc: "/image/i5.jpg",
  },
  {
    title: "Use of cold storage",
    content:
      "Cold storage is an indispensable and cost-effective component in both agriculture and the food industry. It supports food security by providing a buffer against seasonal variations in production and demand. Additionally, it reduces waste by enabling proper inventory management, extends product availability to meet consumer needs, and ensures the safety and quality of food throughout the entire supply chain, promoting public health and consumer satisfaction.",
    imageSrc: "/image/i7.jpg",
  },
  {
    title: "Cold storage in Nepal",
    content:
      "In Nepal, cold storage is essential for ensuring food security and lowering post-harvest losses. It aids in the preservation of perishable agricultural products, enabling farmers to keep and sell their products at higher prices during periods of strong demand. This minimizes dependency on imports and fills in supply-demand gaps. Despite infrastructure problems, the public and private sectors are spending money to enhance distribution and storage. Continued investment in cold storage holds great promise for the economy and farmers' livelihoods as Nepal focuses on agricultural growth.",
    imageSrc: "/image/i4.jpg",
  },
];

const Coldstore = () => {
  return (
    <div>
      <TitleCover title="Things to consider" />
      <div className=" bg-gray-100 tracking-wider text-lg lg:py-4 py-2 text-gray-600">
        {sections.map((section, index) => (
          <ColdstoreSection
            key={index}
            index={index}
            title={section.title}
            content={section.content}
            imageSrc={section.imageSrc}
          />
        ))}
      </div>
    </div>
  );
};

export default Coldstore;
