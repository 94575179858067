import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import handleApiRequest from "../../libs/apiHandler";
import Loader from "../loader";

const EditRoom = ({ closeEditRoom, item }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    coldstoreId: item.coldstoreId,
    roomName: item.roomName,
    length: item.length,
    breadth: item.breadth,
    height: item.height,
    unit: item.unit,
    status: item.status,
    capacity: item.capacity,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const calculateCapacity = () => {
    const { length, breadth, height } = formData;
    const mt = 0.7 * parseFloat(length) * parseFloat(breadth) * parseFloat(height);
    return isNaN(mt) ? "" : mt.toFixed(2);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const targetPath = `room/update/${item?._id}`;
      const result = await handleApiRequest("PUT", targetPath, formData, true);
      console.log(result);
      if (result.message) {
        Swal.fire("Success", result.message, "success");
        setLoading(false);
        closeEditRoom();
      } else {
        Swal.fire("Error", "An error occurred while submitting data.", "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div className="fixed top-0 bg-[#ffffff5f] pt-4 h-screen w-full inset-0">
      {loading && <Loader />}
      <div className="max-w-2xl mx-auto bg-gray-100 p-4">
      <h1 className="lg:text-2xl text-lg font-semibold mb-4 text-green-600 mt-4">
        Edit Room
      </h1>
        <button
          onClick={closeEditRoom}
          className="bg-red-500 rounded px-2 py-1 text-white float-right"
        >
          <i className="fas fa-times"></i>
        </button>
        <form className="grid grid-cols-2 gap-2 " onSubmit={handleEdit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="roomname"
            >
              Room Name
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              type="text"
              id="roomName"
              name="roomName"
              value={formData.roomName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="length"
            >
              Length
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              type="number"
              id="length"
              name="length"
              value={formData.length}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="breadth"
            >
              Breadth
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              type="number"
              id="breadth"
              name="breadth"
              value={formData.breadth}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="height"
            >
              Height
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              type="number"
              id="height"
              name="height"
              value={formData.height}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="unit"
            >
              Unit
            </label>
            <select
              className="w-full px-3 py-2 border rounded"
              id="unit"
              name="unit"
              value={formData.unit}
              onChange={handleChange}
            >
              <option value="feet">Feet</option>
              <option value="metre">Metre</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="status"
            >
              Status
            </label>
            <select
              className="w-full px-3 py-2 border rounded"
              id="status"
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="capacity"
            >
              Capacity in {'MT'}
            </label>
            <input
              className="w-full px-3 py-2 border rounded"
              type="text"
              id="capacity"
              name="capacity"
              value={calculateCapacity()}
              readOnly
            />
          </div>
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded col-span-2"
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditRoom;
