import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import ViewRooms from "../common/viewRooms";
import Dreadcrumb from "../dreadcrumb";
import ViewProduct from "../common/viewProduct";
import NewSensorData from "../user/newSensorData";
import Rooms from "../rooms";
import AssignProduct from "./assignProduct";
const ViewColdStore = ({ item, closeView }) => {
  const [products, setProducts] = useState([]);
  const [sensorData, setSensorData] = useState(false);
  const [assignRoom, setAssignRoom] = useState(false);
  const [assignProduct, setAssignProduct] = useState(false);
  const closeAssign = () => {
    setAssignProduct(false);
  };
  const closeRoomForm = () => {
    setAssignRoom(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/api/coldstore/get-product/${item._id}`,
          {
            headers: {
              "user-token": Cookies.get("user-token"),
            },
          }
        );
        setProducts(response.data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="fixed bg-gray-100 z-10 px-4 inset-0 rounded shadow lg:w-[100%] w-full overflow-y-auto">
      {assignRoom && <Rooms coldstore={item} closeRoomForm={closeRoomForm} />}
      {assignProduct && <AssignProduct closeAssign={closeAssign} item={item} />}
      <div className="flex justify-between py-4">
        <button
          onClick={() => setSensorData(!sensorData)}
          className="px-2 py-2 rounded bg-green-600 text-white mt-2"
        >
          {sensorData ? "close Sensor Data" : "View Sensor Data"}
        </button>
        <button
          onClick={closeView}
          className="bg-red-500 rounded px-2 py-1 text-white my-2"
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
      {sensorData && <NewSensorData userId={item.user?._id} />}
      <Dreadcrumb page="coldstore details" />
      <p className="capitalize lg:text-2xl text-lg font-semibold text-green-600 mt-4">
        coldstore details
      </p>
      {item && (
        <>
          <div className="grid grid-cols-2 gap-4 shadow text-gray-600 tracking-wider p-4 rounded mt-4 space-y-2">
            <div className="col-span-2 sm:col-span-1 shadow lg:w-[50%] w-full p-2">
              <p className="font-bold">Name:</p>
              <p>{item.name}</p>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <p className="font-bold">Coldstore status</p>
              <button
                disabled
                className={`px-2 py-1 rounded mt-2 ${
                  item.status === "pending"
                    ? "text-white bg-yellow-600"
                    : "text-white bg-green-600"
                }`}
              >
                {item.status}
              </button>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <p className="font-bold">User:</p>
              <p>{item.user?.fullName}</p>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <p className="font-bold">Established:</p>
              <p>{item.established.split("T")[0]}</p>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <p className="font-bold">Province ID:</p>
              <p>{item.provinceId?.name}</p>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <p className="font-bold">District ID:</p>
              <p>{item.districtId?.name}</p>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <p className="font-bold">Municipality ID:</p>
              <p>{item.municipalityId?.name}</p>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <p className="font-bold">Ward:</p>
              <p>{item.ward}</p>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <p className="font-bold">Tole:</p>
              <p>{item.tole}</p>
            </div>
          </div>
        </>
      )}
      <div className="mt-6">
        <Dreadcrumb page="rooms" />
        <div>
          <button onClick={() => setAssignRoom(true)} className="bg-green-600 text-gray-50 px-2 py-1 rounded mt-2 capitalize">add more rooms</button>
        </div>
        <ViewRooms coldstore={item} />
      </div>
      <div className="mt-6">
        <Dreadcrumb page="products" />
        <div>
          <button onClick={() => setAssignProduct(true)} className="bg-green-600 text-gray-50 px-2 py-1 rounded mt-2 capitalize">assign more products</button>
        </div>
        <ViewProduct coldstore={item} />
      </div>
    </div>
  );
};

export default ViewColdStore;
