import React from "react";
import Breadcrumb from "../../components/site/breadcrumb";
import Contact from "../../components/site/contact";
import TitleCover from "../../components/site/titleCover";

const ContactPage = () => {
  return (
    <div>
      <Breadcrumb page="Contact" />
      {/* <TitleCover title="Contact" /> */}
      <div className="mt-5">
        <Contact />
       
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.896445360687!2d85.3322135!3d27.689595299999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19bdb4a935ff%3A0xe7b7ad1eb66d42a5!2sR%20%26%20D%20Innovative%20Solution!5e0!3m2!1sen!2snp!4v1697782458256!5m2!1sen!2snp" width="100%" height="650" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  );
};

export default ContactPage;
