import React from 'react'
import TitleCover from './titleCover'
import { Link } from 'react-router-dom'

const Blog = () => {
  return (
    <div className="py-5">
      <TitleCover title="Updates & Blogs" />
      <div className="grid lg:grid-cols-4 grid-cols-1 gap-3 py-4 container mx-auto">
        {[1, 2, 3, 4].map((item, i) => (
        <div className="flex items-center justify-center p-3">
        <div className="w-full max-w-md  mx-auto rounded shadow overflow-hidden">
          <div className="max-w-md mx-auto">
            <img
              src="https://images.unsplash.com/photo-1682685797769-481b48222adf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHwxMTl8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=400&q=60"
              alt=""
            />
            <div className="p-4 sm:p-6">
              <p className="font-bold text-green-700 text-[22px] leading-7 mb-1">
                Blog title {i + 1}
              </p>
              <p className="text-[#7C7C80] font-[15px] lg:mt-6 mt-2">
                Our shrimp sauce is made with mozarella, a creamy taste of shrimp
                with extra kick of spices.
              </p>
  
              <p className="block lg:mt-8 mt-2 w-[40%] px-2 py-2 font-medium tracking-wide text-center capitalize transition-colors duration-300 transform bg-green-600 text-white rounded hover:bg-green-800 focus:outline-none focus:ring focus:ring-teal-300 focus:ring-opacity-80">
                <Link to="/blog/abcdefg">View More</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
        ))}
      </div>
    </div>
  )
}

export default Blog