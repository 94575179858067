import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import NepaliDatepicker from "nepali-datepicker-and-dateinput";
import axios from "axios";
import Loader from "../loader";
import handleApiRequest from "../../libs/apiHandler";
const EditColdStore = ({ item, closeEditColdstore }) => {
  const [userList, setUserList] = useState([""]);
  const [name, setName] = useState(item.name);
  const [user, setUser] = useState(item.user?._id);
  const [ward, setWard] = useState(item.ward);
  const [tole, setTole] = useState(item.tole);
  const [loading, setLoading] = useState("");
  const [established, setEstablished] = useState(item.established);
  const [status, setStatus] = useState(item.status);
  const [provinceId, setProvinceId] = useState(item.provinceId?._id);
  const [districtId, setDistrictId] = useState(item.districtId?._id);
  const [municipalityId, setMunicipalityId] = useState(
    item.municipalityId?._id
  );
  const [provinces, setProvinces] = useState("");
  const [districts, setDistricts] = useState("");
  const [municipalities, setMunicipalities] = useState("");
  const [filteredDistrict, setFilteredDistrict] = useState([]);
  const [filteredMunicipality, setFilteredMunicipality] = useState([]);
  const handleDateChange = (name, dateInMilli, bsDate, adDate) => {
    setEstablished(bsDate);
  };

  const formdata = {
    name,
    user,
    established,
    provinceId,
    districtId,
    municipalityId,
    ward,
    tole,
    status,
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const targetPath = `coldstore/update/${item?._id}`;
      const result = await handleApiRequest("PUT", targetPath, formdata, true);
      console.log(result);
      if (result.message) {
        Swal.fire("Success", result.message, "success");
        setLoading(false);
        closeEditColdstore();
      } else {
        Swal.fire("Error", "An error occurred while submitting data.", "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    const fetchMunicipalities = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/location/municipality/get-all`
      );
      setMunicipalities(response.data);
    };
    fetchMunicipalities();
  }, []);

  useEffect(() => {
    const fetchDistricts = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/location/district/get-all`
      );
      setDistricts(response.data);
    };
    fetchDistricts();
  }, []);
  useEffect(() => {
    try {
      const fetchUsers = async () => {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/api/user`
        );
        setUserList(response.data);
      };
      fetchUsers();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const fetchProvinces = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/location/province/get-all`
      );
      setProvinces(response.data);
    };
    fetchProvinces();
  }, []);

  const filterDistrict = (provinceId) => {
    const data = districts.filter(
      (item, i) => item.provinceId?._id === provinceId
    );
    setFilteredDistrict(data);
  };
  
  const filterMuniciplity = (districtId) => {
    const data = municipalities?.filter(
      (item, i) => item.districtId?._id === districtId
    );
    setFilteredMunicipality(data);
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="flex items-center justify-center">
        <div className="mx-auto w-full max-w-[900px] shadow p-4">
        <button
          onClick={closeEditColdstore}
          className="bg-red-500 rounded px-2 py-1 text-gray-100 float-right"
        >
          <i className="fas fa-times"></i>
        </button>
          <p className="py-2 lg:text-xl text-lg">Edit Coldstore.</p>
          <form action="https://formbold.com/s/FORM_ID" method="POST">
            <div className="grid lg:grid-cols-2 gap-8">
              <div className="mb-5 lg:col-span-1  col-span-2">
                <label
                  for="coldstore name"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  coldstore name
                </label>
                <input
                  type="text"
                  name="coldstore_name"
                  id="name"
                  placeholder="Enter store name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>
              <div className="mb-5  lg:col-span-1  col-span-2">
                <label
                  for="coldstore name"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  assign to user
                </label>
                <select
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  name="user"
                  id="user"
                >
                  <option>select user</option>
                  {userList.map((user, i) => (
                    <option
                      required={item.user._id === user._id ? "selected" : null}
                      value={user._id}
                      key={i}
                    >
                      {user.fullName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-5 col-span-2">
                <label
                  for="coldstore location"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  coldstore location
                </label>
                <div className="grid lg:grid-cols-3 grid-col-1 gap-4">
                  <div>
                    <p className="text-lg font-semibold py-2">
                      {item.provinceId?.name}
                    </p>
                    <select
                      onChange={(e) => {
                        setProvinceId(e.target.value);
                        filterDistrict(e.target.value);
                      }}
                      value={provinceId}
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    >
                      <option value="">change province</option>
                      {provinces &&
                        provinces.map((item, i) => (
                          <option key={i} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div>
                    <p className="text-lg font-semibold py-2">
                      {item.districtId?.name}
                    </p>
                    <select
                      onChange={(e) => {
                        setDistrictId(e.target.value);
                        filterMuniciplity(e.target.value);
                      }}
                      value={districtId}
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    >
                      <option value="">select district</option>
                      {districts &&
                        filteredDistrict.map((item, i) => (
                          <option key={i} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div>
                    <p className="text-lg font-semibold py-2">
                      {item.municipalityId?.name}
                    </p>
                    <select
                      onChange={(e) => {
                        setMunicipalityId(e.target.value);
                      }}
                      value={municipalityId}
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    >
                      <option value="">select municipality</option>
                      {municipalities &&
                        filteredMunicipality.map((item, i) => (
                          <option key={i} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <input
                    type="text"
                    name="wardno"
                    id="wardno"
                    placeholder="Enter ward number"
                    value={ward}
                    onChange={(e) => setWard(e.target.value)}
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                  <input
                    type="text"
                    name="tole"
                    id="tole"
                    placeholder="Enter tole"
                    value={tole}
                    onChange={(e) => setTole(e.target.value)}
                    className="w-full rounded-md border border-[rgb(224,224,224)] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
              <div className="mb-5  lg:col-span-1  col-span-2">
                <label
                  for="coldstore name"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Year of establishment
                </label>
                <NepaliDatepicker
                  id="nepali-date"
                  onChange={handleDateChange}
                  label="नेपाली मिति"
                  defaultDate="2077-01-01"
                  defaultFormat
                />
              </div>

              <div>
                <label
                  for="coldstore status"
                  className="mb-3 block text-base font-medium text-[#07074D]"
                >
                  coldstore status
                </label>
                <div className="mb-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      value="approved"
                      checked={status === "approved"}
                      onChange={(e) => setStatus(e.target.value)}
                      className="form-checkbox h-5 w-5 text-indigo-600"
                    />
                    <span className="ml-2">Approved</span>
                  </label>
                </div>
                <div className="mb-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      value="pending"
                      checked={status === "pending"}
                      onChange={(e) => setStatus(e.target.value)}
                      className="form-checkbox h-5 w-5 text-indigo-600"
                    />
                    <span className="ml-2">Pending</span>
                  </label>
                </div>
                <div className="mb-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      value="cancelled"
                      checked={status === "cancelled"}
                      onChange={(e) => setStatus(e.target.value)}
                      className="form-checkbox h-5 w-5 text-indigo-600"
                    />
                    <span className="ml-2">cancelled</span>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <button
                onClick={handleUpdate}
                className="hover:shadow-form rounded-md bg-green-600 py-3 px-8 text-base font-semibold text-white outline-none"
              >
                update coldstore
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditColdStore;
