import React from 'react'
import Breadcrumb from '../../components/site/breadcrumb'
import Services from '../../components/site/services'

const ServicePage = () => {
  return (
    <div>
        <Breadcrumb page="Service"/>
      <div className="container mx-auto">
      <Services />
      </div>
    </div>
  )
}

export default ServicePage